export interface ILog {
    message: string;
    applicationName: string;
    browserName: string;
    browserVersion: string;
    frontendFeature: string | null;
    logLevel: string;
    additionalProperty: { [key: string]: string };
    stackTrace: string;
}

export enum LogFeature {
    'EL-CreateLogs' = 'Created new log for assetKey ',
    'EL-UpdateLogs' = 'Update log for assetKey ',
    'EL-LogsFlagged' = 'Updated flag for the logs of assetKey ',
 	'EL-LogsReply' = 'Added reply to log to the parent log',
 	'EL-UploadMedia' = 'Uploaded a media file for asset logs for assetKey ',
 	'EL-DeleteLogs' = 'Deleted Log for assetKey ',
 	'EL-LogsFilter' = 'Fetched filtered logs with filter '
}
