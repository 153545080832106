import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IHBBurnerListServiceResponse } from '@/view-models/burner/burner-response-view-models';

export class HierarchyBuilderService extends BaseService {

  constructor(baseUrl: string, axiosInstance: AxiosInstance) {
    super(`${baseUrl}`, axiosInstance);
  }

  public async getBurnerList(selectedAssetKey: string): Promise<IHBBurnerListServiceResponse> {
    const url = `heater/${selectedAssetKey}/burnertree`;
    return this.httpClient.get<IHBBurnerListServiceResponse>(url);
  }

}
