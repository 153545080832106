var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library"},[_c('div',{staticClass:"library__upload-section"},[_c('label',{class:[
        _vm.isLoading || _vm.maximumMediaLimitReached
          ? 'library__upload-photo-video__disabled'
          : 'library__upload-photo-video__enabled',
        'library__upload-photo-video' ],attrs:{"for":"library__upload-photo-video-input"}},[_c('Icon',{class:[
          _vm.isLoading || _vm.maximumMediaLimitReached
            ? 'library__upload-icon__disabled'
            : 'library__upload-icon__enabled',
          'library__upload-icon'
        ],attrs:{"name":"upload"}}),_c('span',{class:[
          _vm.isLoading || _vm.maximumMediaLimitReached
            ? 'library__upload-label__disabled'
            : 'library__upload-label__enabled',
          'library__upload-label'
        ]},[_vm._v(" "+_vm._s(_vm.$t('media.mediaSize'))+" ")]),_c('input',{staticClass:"d-none",attrs:{"id":"library__upload-photo-video-input","type":"file","accept":"image/*,video/*","multiple":"multiple","disabled":_vm.isLoading},on:{"change":function($event){return _vm.uploadPhotoVideo($event)},"click":function($event){$event.target.value = ''}}})],1),(_vm.maximumMediaLimitReached)?_c('div',{staticClass:"library__upload-section__max-limit-message"},[_c('Icon',{staticClass:"library__upload-section__max-limit-message__icon",attrs:{"name":"video-failed-info","view-box":"0 0 22 22"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('media.maxMedia'))+" "+_vm._s(_vm.maximumMediasLimit)+" "+_vm._s(_vm.$t('media.filesReached'))+".")])],1):_vm._e()]),(_vm.medias.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"library__no-contents"},[_c('Icon',{attrs:{"name":"no-contents-arrow","fill":"var(--color-dark-500)","view-box":"10 5 24 24","width":"350px","height":"200px"}}),_c('span',{staticClass:"library__no-contents-text"},[_vm._v(" "+_vm._s(_vm.$t('media.getStartedBy'))+" "+_vm._s(_vm.maximumMediasLimit)+" "+_vm._s(_vm.$t('media.medias'))+". ")])],1):(_vm.isLoading)?_c('div',{staticClass:"library__contents__loading"},[_c('Loading')],1):_c('div',{staticClass:"library__contents"},_vm._l((_vm.medias),function(media){return _c('MediaLibraryItem',{key:media.imageKey,staticClass:"library__contents__media-item",attrs:{"media":media}})}),1),_c('b-modal',{attrs:{"id":"fileTypeError","title":"Error","static":"","centered":"","ok-only":""}},[_vm._v(" "+_vm._s(_vm.$t('media.fileFormatError'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }