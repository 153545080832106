


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
// Components
import MediaPreviewer from '@/components/media/MediaPreviewer.vue';
import MediaViewerCarousel from '@/components/media/MediaViewerCarousel.vue';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';
import store, { VuexModuleNamespaces } from '@/store';
import { ProductLogStore } from '@/store/productLog/productLogStore';

Vue.use(BootstrapVue);

@Component({
  name: 'media-viewer-modal',
  components: {
    MediaPreviewer,
    MediaViewerCarousel,
  },
})
export default class MediaViewerModal extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public productKey!: string;
  @Prop({ required: true })
  public logKey!: string;
  @Prop({ required: true })
  public medias!: IProductLogMediaViewModel[];

  // Lifecycle Hooks...
  public destroyed() {
    store.commit(`${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.clearMediaCaches.name}`);
  }

  // Event Methods...
  private close(): void {
    this.$bvModal.hide(`media-viewer-modal[${this.logKey}]`);
  }
}
