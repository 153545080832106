import ConfigFactory from '@/services/config/config';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { AppStore } from '@/store/app/appStore';
import { getParentAppSdk, IAuthClient, authClient as parentAuthClient, inParentApp } from '../fromParent';
import convict from 'convict';

let authClient: IAuthClient | null = null;

function getProductLoggingRedirectUri(conf: convict.Config<any>): string {
  if (process.env.VUE_APP_AUTH0_REDIRECT_URI) {
    // If the app is running in isolation, then return the .env var
    return process.env.VUE_APP_AUTH0_REDIRECT_URI;
  } else if ((window as any).eftEventBus) {
    // If the app is running inside the parent app, then return parent redirect
    return conf.get('redirectUriParent');
  } else if (store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.standaloneRedirect.name}`]) {
    // If the app is running inside another app in CF (and not in the parent app),
    // then return to the origin of the app that called it
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.standaloneRedirect.name}`];
  } else if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    // If the app is running on localhost, then return to localhost
    return 'http://localhost:8080';
  } else {
    // Else, return standalone config
    return conf.get('redirectUriStandalone');
  }
}

async function getAuthClient(): Promise<IAuthClient> {
  // If Auth0 client already initialized, return it.  Else, create a new one.
  if (authClient == null) {
    // Retrieve app configs
    const conf = await ConfigFactory.GetConfig();
    let client = getParentAppSdk()?.authClient;

    if (client == null) {
      client = parentAuthClient;
      await client.init({
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN
          ? process.env.VUE_APP_AUTH0_DOMAIN : conf.get('domain'),
        authClientID: process.env.VUE_APP_AUTH0_CLIENT_ID
          ? process.env.VUE_APP_AUTH0_CLIENT_ID : conf.get('clientId'),
        authAudience: process.env.VUE_APP_AUTH0_AUDIENCE
          ? process.env.VUE_APP_AUTH0_AUDIENCE : conf.get('audience'),
        authRedirectUri: getProductLoggingRedirectUri(conf)
      });
    }

    authClient = client;
  }

  return authClient;
}

export async function getAuthToken(): Promise<any> {
  const client = await getAuthClient();
  return client.getAuthToken();
}

export const authGuard = async (to: any, from: any, next: () => void) => {
  // Silently check for the presence of a token
  getAuthToken().then(() => {
    next();
  }).catch(async () => {
    const client = await getAuthClient();
    client.loginWithRedirect();
  });
};

if (!inParentApp()) {
  window.addEventListener('load', async () => {
    if ( window.location.search.includes('code=') &&
        window.location.search.includes('state=')) {
      try {
        // Only process this event if you are expecting it, i.e., your Auth0 client is initialized
        if (authClient !== null) {
          const client = await getAuthClient();
          await client.handleRedirectCallback();
        }
      } catch (err) {
        const errorString = 'Authorization error. \n' + JSON.stringify(err);
        await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.sendErrorLog.name}`, err);
        await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.setError.name}`, {
          error: errorString,
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }
  });
}
