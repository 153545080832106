import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IHDTask } from '@/view-models/task/task-response-view-models';

export class AssetTaskService extends BaseService {

  constructor(ihdApiUrl: string, axiosInstance: AxiosInstance) {
    super(`${ihdApiUrl}/api`, axiosInstance);
  }

  public async getTaskList(selectedAssetKey: string): Promise<IHDTask[]> {
    const url = `diagnostics/asset/${selectedAssetKey}/tasks`;
    return this.httpClient.get<IHDTask[]>(url);
  }

}
