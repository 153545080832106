export interface IErrorBusMessage {
    errorTag: string;
    error: any;
    logSilently: boolean; // default false,
    showMessage: boolean; // default true
}

export interface IErrorMessageConfig {
    logSilently?: boolean;
    showMessage?: boolean;
}

export class ErrorBusMessage implements IErrorBusMessage {

    public errorTag: string;
    public error: any;
    public logSilently: boolean = false;
    public showMessage: boolean = true;

    constructor(errorTag: string, error: any, config?: IErrorMessageConfig) {
        this.errorTag = errorTag;
        this.error = error;
        this.logSilently = config && config.logSilently != null ? config.logSilently : this.logSilently;
        this.showMessage = config && config.showMessage != null ? config.showMessage : this.showMessage;
    }

}
