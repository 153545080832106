import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IUser } from '@/view-models/user/user-view-models';

export class UserService extends BaseService {

  constructor(umApiUrl: string, axios: AxiosInstance) {
    super(umApiUrl, axios);
  }

  public async getUser(): Promise<IUser> {
    return this.httpClient.get<IUser>('users/current');
  }
}
