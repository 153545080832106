import axios, { AxiosInstance } from 'axios';
import convict, { Config } from 'convict';

// Note: currently, local .env files will overwrite config.json
// (see https://cli.vuejs.org/guide/mode-and-env.html) for more details.  Possible
// to redesign config injection pattern in future sprint.  DBB 5/7/2020
export default class ConfigFactory {

  public static async GetConfig(): Promise<Config<unknown>> {
    if (this.config === undefined &&
      this.instance !== undefined) {
      const d = await this.instance.get('config.json');
      this.config = convict(d.data);
    }
    return this.config;
  }

  private static config: Config<unknown>;

  private static readonly instance: AxiosInstance = axios.create({
    // Setting the base URL is performed by accessing the origin of the asset
    // diagram builder (ADB) component script.  Becuase ADB and asset diagram viewer (ADV)
    // are served from the same location, this code works for both apps.  If ADV
    // is moved away from ADB, then this code will need to be updated.
    baseURL: ConfigFactory.getBaseUrl(),
    timeout: 5000
  });

  private static getBaseUrl(): string {
    if (process.env.VUE_APP_BASE_URL) {
      return process.env.VUE_APP_BASE_URL;
    }
    return (document.getElementById('product-logging-script') ? new URL((document.getElementById('product-logging-script') as HTMLScriptElement)!.src).origin : '/');
  }

}
