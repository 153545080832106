


















































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue, BDropdown } from 'bootstrap-vue';
import CommentEditor from '@/components/productLogs/CommentEditor.vue';
import ProductLogs from '@/components/productLogs/ProductLogs.vue';
import store, { VuexModuleNamespaces } from '@/store';
import {
  FilterType, DefaultOptionsUpdated, LVOptionsUpdated, TowerOptions
  // ConfirmDiscardContext
} from '@/view-models/productLog/product-log-view-models';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import {AppStore, LogsMode} from '@/store/app/appStore';
import ConfirmDiscardCommentModal from '@/components/modals/ConfirmDiscardCommentModal.vue';
import { ErrorStore } from '@/store/error/errorStore';
import { UserStore } from '@/store/user/userStore';
import { TransactionName } from '@/enums/transaction-name';
import BaseComponent from '../common/BaseComponent';

Vue.use(BootstrapVue);

@Component({
  name: 'logs',
  components: {
    ProductLogs,
    CommentEditor,
    ConfirmDiscardCommentModal
  }
})
export default class Logs extends BaseComponent {
  // Properties...
  private selectedFilterType: string = '';
  private isCommentMenuActive: boolean = false;
  private FilterType = FilterType;
  private isFilterOpen: boolean = false;
  private showTransparentBackground = false;
  private isCommentingModeActive = false;
  private productKey = '';

  public get logsMode(): LogsMode {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
  }

  public get options(): Array<FilterType> {
    if (this.logsMode && this.logsMode === LogsMode.TOWER) {
      return TowerOptions;
    }
    return this.getFlaggedOptions();
  }

  public get parentAppName() {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.parentApp.name}`];
  }

  public get ihdFlag() {
    return false;
  }
  public get isLayoutViewer() {
    return this.parentAppName === 'asset-diagram-viewer' || this.logsMode === LogsMode.BURNER;
  }
  // Lifecycle hooks...
  public async mounted() {
    this.startTransaction(TransactionName.InitialLoad);
    this.productKey = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.productKey.name}`];
    this.selectedFilterType = FilterType.ALL;
    this.isCommentMenuActive = false;
    this.isFilterOpen = false;
  }

  public async created() {
    // initialize the user on creation
    await this.initializeCurrentUser();

    plEventBus.$on(plEvents.commentEditorClosed, () => {
      plEventBus.$emit(plEvents.activeCommentingModeChanged, false);
      plEventBus.$emit(plEvents.contentScrollable, true);
      this.showTransparentBackground = false;
      this.isCommentMenuActive = false;
    });

    plEventBus.$on(plEvents.commentAddedOrDeleted, async () => {
      this.changeCommentFilterType(FilterType.ALL);
    });

    plEventBus.$on(plEvents.showTransparentOverlay, (value: boolean) => {
      this.showTransparentBackground = value;
    });

    plEventBus.$on(plEvents.activeCommentingModeChanged, async (value: boolean) => {
      this.isCommentingModeActive = value;
    });

    plEventBus.$on(plEvents.setProductKey, async (key: string) => {
      this.productKey = key;
      this.selectedFilterType = !this.isLayoutViewer ? FilterType.FLAGGED : FilterType.ALL;
    });
  }

  public clearLogsEditor(value: boolean) {
    if (value) {
      this.tryCloseCommentEditor();
    }
  }

  public tryCloseCommentEditor() {
    if (this.isCommentingModeActive) {
      this.$bvModal.show('confirmDiscardCommentModal');
    } else {
      plEventBus.$emit(plEvents.commentEditorClosed);
    }
  }

  public changeCommentFilterType(option: FilterType) {
    this.startTransaction(TransactionName.LogFilter);
    this.isFilterOpen = false;
    this.selectedFilterType = option;
    plEventBus.$emit(plEvents.changedDisplay);
  }

  public addComment() {
    this.startTransaction(TransactionName.NewLog);
    plEventBus.$emit(plEvents.commentEditorShown);
    this.isCommentMenuActive = true;
    this.showTransparentBackground = true;
  }

  public toggleDropdown() {
    // Ensure that menu actually opens.
    this.isFilterOpen = !this.isFilterOpen;
    const filterDropdown = this.$refs.filterDropdown as BDropdown;
    if (this.isFilterOpen) {
      filterDropdown.show();
    } else {
      filterDropdown.hide();
    }
  }

  // Private methods...
  private async initializeCurrentUser() {
    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () => {
          await store.dispatch(`${VuexModuleNamespaces.user}/${UserStore.actions.getCurrentUser.name}`);
        },
        errorMsg: 'Error initializing current user: ',
      }
    );
  }

  private getFlaggedOptions(): Array<FilterType> {
    if (this.isLayoutViewer) {
      return LVOptionsUpdated;
    }
    let options = DefaultOptionsUpdated;
    if (this.ihdFlag && options.indexOf(FilterType.TASK) < 0) {
      options.push(FilterType.TASK);
    }

    return options;
  }
}
