import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from '@/store/app/appStore';
import { ProductLogStore, IProductLogStoreState } from '@/store/productLog/productLogStore';
import { ErrorStore, IErrorStoreState } from '@/store/error/errorStore';
import { IProductLogMediaLibraryStoreState, ProductLogMediaLibraryStore } from '@/store/productLogMediaLibrary/productLogMediaLibraryStore';
import { UserStore, IUserStoreState } from '@/store/user/userStore';
import { BurnerStore, IBurnerStoreState } from '@/store/burner/burnerStore';
import { ITaskStoreState, TaskStore } from './task/taskStore';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  error: IErrorStoreState;
  productLog: IProductLogStoreState;
  productLogMediaLibrary: IProductLogMediaLibraryStoreState;
  user: IUserStoreState;
  burner: IBurnerStoreState;
  task: ITaskStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    error: ErrorStore,
    productLog: ProductLogStore,
    productLogMediaLibrary: ProductLogMediaLibraryStore,
    user: UserStore,
    burner: BurnerStore,
    task: TaskStore
  }
});

// update these module namespaces as registered module names are update above
export const VuexModuleNamespaces = {
  app: 'app',
  error: 'error',
  productLog: 'productLog',
  productLogMediaLibrary: 'productLogMediaLibrary',
  user: 'user',
  burner: 'burner',
  task : 'task'
};
