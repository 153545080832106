import Vue from 'vue';

export const plEventBus = new Vue();

export const plEvents = {
  setProductKey: 'set-product-key',

   // Modals
  mediaLibraryModalClosed: 'mediaLibraryModal::closed',

  // Media Library
  libraryMediaSelected: 'libraryMediaSelected',
  selectedLibraryMediasCounted: 'selectedLibraryMediasCounted',
  uploadingMedias: 'uploadingMedias',

  // Media Viewer
  showMediaInPreviewer: 'showMediaInPreviewer',
  viewerCarouselItemSelected: 'viewerCarouselItemSelected',

  // Dropdown menu
  changedDisplay: 'changedDisplay',

  // Product Log
  tryUpdateProductFlag: 'tryUpdateProductFlag',
  productFlagUpdated: 'productFlagUpdated',

  // Comment Editor
  tryAddorEditComment: 'tryAddorEditComment',
  commentAdded: 'commentAdded',
  commentAddedOrDeleted: 'commentAddedOrDeleted',
  showTransparentOverlay: 'showTransparentOverlay',
  activeCommentingModeChanged: 'active-commenting-mode-changed',
  commentEditorShown: 'comment-editor-shown',
  commentEditorClosed: 'commentEditorClosed',
  replyEditorShown: 'reply-editor-shown',
  contentScrollable: 'contentScrollable',

  // Delete
  tryDeleteComment: 'tryDeleteComment'
};

export const plExternalEvents = {
  // Global event emitted externally
  plCommentModeStarted: 'pl-comment-mode-started',
  plCommentModeEnded: 'pl-comment-mode-ended',
  plInActiveCommentingMode: 'pl-in-active-commenting-mode',
  plProductLogFlagUpdated: 'pl-product-log-flag-updated',
};
