



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { plEventBus, plEvents } from '@/services/eventBus/product-logging-event-bus';
import store, { VuexModuleNamespaces } from '@/store';
import { ErrorStore } from '@/store/error/errorStore';
import { ProductLogStore } from '@/store/productLog/productLogStore';
import Loading from '@/components/common/Loading.vue';
import { IDeleteEntityLogRequest } from '@/view-models/productLog/product-log-view-models';
Vue.use(BootstrapVue);

@Component({
  name: 'confirm-delete',
  components: {
    Loading
  }
})
export default class ConfirmDeleteModal extends Vue {
  // Vue.JS Props...
  @Prop({ required: true })
  public logKey!: string;
  @Prop({ required: true })
  public entityLogKey!: string;
  @Prop({ required: true})
  public isParentComment!: boolean;
  @Prop()
  public parentCommentHasReplies!: boolean;

  public isLoading: boolean = false;

  // Event Methods...
  private mounted() {
    this.isLoading = false;
  }

  private cancel(): void {
    this.isLoading = false;
    this.$bvModal.hide('confirm-delete-modal');
  }

  private async confirmDelete(): Promise<void> {
    this.isLoading = true;
    const deleteRequest: IDeleteEntityLogRequest = {
      logKey: this.logKey,
      entityKey: this.entityLogKey
    };
    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
      action: async () => store.dispatch(
        `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.deleteLog.name}`, deleteRequest),
      errorMsg: 'Error deleting comment ',
      routeHomeAfterError: false
    });
    plEventBus.$emit(plEvents.commentAddedOrDeleted);
    this.$bvModal.hide('confirm-delete-modal');
    this.isLoading = false;
  }
}
