// Provides Elastic APM for Frontends
import Vue from 'vue';
import { apm, Transaction } from '@elastic/apm-rum';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { TransactionName } from '@/enums/transaction-name';
import productLoggingRouter from '@/ProductLogging.vue';
export function elasticRum() {
  const options = {
    router: productLoggingRouter,
    config: {
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: 'Product Logging Frontend',
      serverUrl: 'https://2e3d96a3120441ab89c5bd14b569dcf0.apm.us-east-1.aws.cloud.es.io:443',
      environment: process.env.NODE_ENV,
      distributedTracingOrigins: [
        /https?:\/\/localhost:\d{4}/, // localhost services
        /https?:\/\/([a-z0-9-]+[.])*kesportal(dev|qa|uat|prod)[.]com(\/.*)/ // dev/qa/uat/prod services
      ],
      propagateTracestate: true,
      //  disableInstrumentations: ['page-load', 'history', 'xmlhttprequest', 'fetch', 'eventtarget'],
      active: true
    }
  };
  if (options.config.environment !== 'production' && options.config.environment !== 'uat') {
    Vue.use(ApmVuePlugin, options);
    const transaction = apm.startTransaction(TransactionName.InitialLoad, 'custom-page-load');
    setTransaction(transaction, 'customPageLoad');
  }
}
const transactionDictionary: Map<string, Transaction> = new Map<string, Transaction>();
export function getTransaction(transactionKey: string) {
  return transactionDictionary.get(transactionKey);
}
export function setTransaction(transaction: any, transactionKey: string) {
  transactionDictionary.set(transactionKey, transaction);
}
export function deleteTransaction(transactionKey: string) {
  transactionDictionary.delete(transactionKey);
}
