



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  IProductLogMediaViewModel,
  IProductLogMediaCache,
} from '@/view-models/productLog/product-log-view-models';
import store, { VuexModuleNamespaces } from '@/store';

// Components...
import FullscreenImageViewerModal from '@/components/modals/FullscreenImageViewerModal.vue';
import Loading from '@/components/common/Loading.vue';
import {
  ProductLogStore,
  IDownloadMediaRequest,
} from '@/store/productLog/productLogStore';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import sharedAxiosInstance from '@/services/common/api-service';
import { ErrorStore } from '@/store/error/errorStore';

Vue.use(BootstrapVue);

@Component({
  name: 'media-previewer',
  components: {
    FullscreenImageViewerModal,
    Loading,
  },
})
export default class MediaPreviewer extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public productKey!: string;

  // Properties...
  private isLoading: boolean = true;
  private media: IProductLogMediaViewModel | null = null;
  private mediaSource: string = '';

  // Lifecycle hooks...
  public created(): void {
    plEventBus.$on(
      plEvents.showMediaInPreviewer,
      async (mediaToView: IProductLogMediaViewModel) => {
        if (mediaToView) {
          this.isLoading = true;
          this.media = mediaToView;

          // check if media is already cached, if not download it
          const mediaCache: IProductLogMediaCache = ProductLogStore.state.mediaCaches.find(
            (cache) => cache.key === mediaToView.imageKey
          )!;

          if (mediaCache) {
            this.mediaSource = mediaCache.mediaSource;
            this.isLoading = false;
          } else {
            const downloadMediaRequest: IDownloadMediaRequest = {
              key: this.productKey,
              mediaKey: this.media.imageKey ? this.media.imageKey : '',
            };
            await store
              .dispatch(
                `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.downloadMedia.name}`,
                downloadMediaRequest
              )
              .then(async (preSignedUrl) => {
                await store.dispatch(
                  `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
                  {
                    action: async () => {
                      await sharedAxiosInstance
                        .get(preSignedUrl)
                        .then(async (result) => {
                          const newMediaCache: IProductLogMediaCache = {
                            key: mediaToView.imageKey ? mediaToView.imageKey : '',
                            mediaSource: result.data,
                          };
                          store.commit(
                            `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.updateMediaCaches.name}`,
                            newMediaCache
                          );
                          this.mediaSource = newMediaCache.mediaSource;
                        });
                      this.isLoading = false;
                    },
                    errorMsg: 'Failed downloading media using presigned url: ',
                  }
                );
              });
          }
        }
      }
    );
  }

  public destroyed() {
    plEventBus.$off(plEvents.showMediaInPreviewer);
  }

  private openFullscreenImageViewer() {
    if (!this.media?.isVideo) {
      this.$bvModal.show('fullscreen-image-viewer-modal');
    }
  }
}
