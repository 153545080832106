export default class JsonLocalStorage<TState> {
  public constructor(private namespace: string, private defaultState: TState) {}

  public get state(): TState {
    const storageItem = localStorage.getItem(this.namespace);
    const state: TState = storageItem == null
      ? this.defaultState : JSON.parse(storageItem);

    return state;
  }

  public set state(newState: TState) {
    const json = JSON.stringify(newState);
    localStorage.setItem(this.namespace, json);
  }

  public patchUpdate(partial: Partial<TState>): void {
    const newState: TState = {
      ...this.defaultState,
      ...this.state,
      ...partial
    };

    this.state = newState;
  }

  public remove(): void {
    localStorage.removeItem(this.namespace);
  }
}
