export enum FilterType {
  ALL = 'ALL',
  FLAGGED = 'FLAGGED',
  LOG = 'LOG',
  SYSTEM = 'SYSTEM',
  ASSET = 'ASSET',
  BURNER = 'BURNER',
  TASK= 'TASK',
  ALLASSETENTRIES = 'ALLASSETENTRIES',
  ASSETUSERENTRIES= 'ASSETUSERENTRIES',
  ASSETSYSTEM= 'ASSETSYSTEM',
  ALLBURNERENTRIES = 'ALLBURNERENTRIES',
  BURNERUSERENTRIES= 'BURNERUSERENTRIES',
  BURNERSYSTEMENTRIES= 'BURNERSYSTEMENTRIES',
  ALLUSERENTRIES= 'ALLUSERENTRIES',
  ALLSYSTEMENTRIES= 'ALLSYSTEMENTRIES'
}

export enum FilterSearchTerm {
  ALL = 'All',
  FLAGGED = 'Flagged',
  BURNER = 'Burner',
  ASSET= 'Asset',
  SYSTEM= 'System',
  LOG= 'Log',
  ALLASSETENTRIES= 'AllAssetEntries',
  ASSETUSERENTRIES= 'AssetUserEntries',
  ASSETSYSTEM= 'AssetSystemEntries',
  ALLBURNERENTRIES= 'AllBurnerEntries',
  BURNERUSERENTRIES= 'BurnerUserEntries',
  BURNERSYSTEMENTRIES= 'BurnerSystemEntries',
  TASK= 'Task',
  ALLUSERENTRIES= 'AllUserEntries',
  ALLSYSTEMENTRIES= 'AllSystemEntries'
}

export enum CommentActionType {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

// internal enums
export enum CommentType {
  SYSTEM = 'SYSTEM',
  LOG = 'LOG',
  REPLY = 'REPLY',
  ACTION = 'ACTION',
  TASK_COMPLETION = 'TASKCOMPLETION'
}

export enum MediaProperty {
  Environment = 'environment',
  Playsinline = 'playsinline',
  Canvas = 'canvas',
  Image = 'image',
  Video = 'video',
  Play = 'play',
  Pause = 'pause'
}

export enum ConfirmDiscardContext {
  CloseSideMenu = 'closeSideMenu',
  SelectNewBurner = 'selectNewBurner'
}

export const DefaultOptionsUpdated: Array<FilterType> = [
  FilterType.ALL,
  FilterType.BURNERUSERENTRIES,
  FilterType.BURNERSYSTEMENTRIES,
  FilterType.FLAGGED,
];

export const LVOptionsUpdated: Array<FilterType> = [
  FilterType.ALL,
  FilterType.FLAGGED,
  FilterType.BURNERUSERENTRIES,
  FilterType.BURNERSYSTEMENTRIES
];

export const TowerOptions: Array<FilterType> = [
  FilterType.ALL,
  FilterType.FLAGGED
];

export interface IProductLogs {
  key: string;
  assetKey: string;
  burnerKey: string;
  burnerName?: string;
  isFlagged: boolean;
  isDeleteAllowed: boolean;
  isEditAllowed: boolean;
  createdDate: string;
  lastModifiedDate: string;
  userKey: string;
  userName: string;
  text: string;
  logType: string;
  parentKey?: string;
  logImages: IProductLogMediaViewModel[];
  taskKey?: string;
  completedAt?: string;
  taskName?: string;
  entityKey?: string;
  entityType?: string;
}

export interface IEntityLogs {
  entityKey: string;
  entityType: string;
  logKey: string;
  parentEntityKey: string;
  entityAncestorKey: string;
  isFlagged: boolean;
  isEditAllowed: boolean;
  isDeleteAllowed: boolean;
  createdAt: string;
  lastModifiedAt: string;
  lastEditedAt: string;
  userKey: string;
  userName: string;
  text: string;
  logType: string;
  parentLogKey: string;
  media: IMediaLogViewModel[];
  key?: string; // used to map to legacy attributes
  assetKey?: string; // used to map to legacy attributes
  burnerKey?: string; // used to map to legacy attributes
  burnerName?: string; // used to map to legacy attributes
  parentKey?: string; // used to map to legacy attributes
  createdDate?: string; // used to map to legacy attributes
  lastModifiedDate?: string; // used to map to legacy attributes
  lastEditedDate?: string; // used to map to legacy attributes
  logImages?: IProductLogMediaViewModel[]; // used to map to legacy attributes
  taskKey?: string;
  taskName?: string;
}

export interface IAddProductLogRequest {
  key?: string;
  text: string;
  logType: string;
  logImages: IProductLogMediaViewModel[];
  assetKey: string;
  burnerKey?: string;
  isFlagged: boolean;
  parentKey?: string;
  taskKey?: string;
  entityKey?: string;
}

export interface IAddEntityLogRequest {
  logKey?: string;
  entityKey: string;
  entityType: string;
  parentEntityKey: string;
  entityAncestorKey: string;
  isFlagged?: boolean;
  text: string;
  media: IMediaLogViewModel[];
  parentLogKey: string | undefined;
  logType: string | undefined;
}

export interface IProductLogFlagUpdateRequest {
  assetKey: string;
  burnerKey: string;
  logKey: string;
  isFlagged: boolean;
}

export interface IEntityLogFlagUpdateRequest {
  entityKey: string;
  logKey: string;
  isFlagged: boolean;
  assetKey: string|null;
}

export interface IDeleteEntityLogRequest {
  entityKey: string;
  logKey: string;
}

export interface IAssetLogFlagUpdateRequest {
  key: string;
  burnerKey?: string;
  isFlagged: boolean;
}

export interface ChildComments {
  [parentKey: string]: IProductLogs[];
}

export interface IProductLogsByDate {
  logsDate: string;
  key: string;
  totalRecords: number;
  userLogs: IProductUserLogsViewModel[];
}

export interface IProductUserLogsViewModel extends IProductLogs {
  actionLogs: IProductLogs[];
  replyLogs: IProductLogs[];
}

export interface IProductLogResponse {
  total: number;
  from: number;
  to: number;
  data: IProductLogs[];
}

export interface IEntityLogResponse {
  total: number;
  from: number;
  to: number;
  data: IEntityLogs[];
}

export interface IProductLogRequest {
  key: string;
  parentKey?: string;
  currentPage?: number;
  perPage?: number;
  searchTerm?: string;
}

export interface IProductLogMediaCache {
  key: string;
  mediaSource: string;
}

export interface IProductLogMediaViewModel {
  s3Path?: string; // used to map to legacy attributes
  mediaKey?: string; // used to map to legacy attributes
  imageKey?: string;
  thumbnail: string;
  title?: string;
  isVideo: boolean;
  isFailedPreview?: boolean;
  isSelected?: boolean;
  isRemoved?: boolean;
  isPrevSelected?: boolean;
}

export interface IMediaLogViewModel {
  mediaKey?: string;
  thumbnail: string;
  isVideo: boolean;
  s3Path?: string;
  imageKey?: string; // used to map to legacy attributes
  title?: string; // used to map to legacy attributes
  isFailedPreview?: boolean; // used to map to legacy attributes
  isSelected?: boolean; // used to map to legacy attributes
  isRemoved?: boolean; // used to map to legacy attributes
  isPrevSelected?: boolean; // used to map to legacy attributes
}

export interface IProductLogUploadImage {
  imageKey: string;
  preSignedUrl: string;
}
