

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import { ProductLogMediaLibraryStore } from '@/store/productLogMediaLibrary/productLogMediaLibraryStore';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'media-library-item',
})
export default class MediaLibraryItem extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public media!: IProductLogMediaViewModel;

  // Properties...
  private selectedMedias: IProductLogMediaViewModel[] = [];

  // Event Methods...
  private deleteMedia(media: IProductLogMediaViewModel): void {
    store.commit(`${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.deleteUploadedMedia.name}`,
                 media);
    plEventBus.$emit(plEvents.libraryMediaSelected);
  }
}
