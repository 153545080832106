import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { AssetTaskService } from '@/services/task/asset-task-service';
import { IHDTask } from '@/view-models/task/task-response-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';

const initTaskLogService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AssetTaskService(
      process.env.VUE_APP_IHD_API_BASE_URL
        ? process.env.VUE_APP_IHD_API_BASE_URL
        : conf.get('ihdApiUrl'),
        sharedAxiosInstance
    );
  };

export interface ITaskStoreState {
    tasks: IHDTask[] | [];
}

export interface TaskStoreGetters extends GetterTree<ITaskStoreState, IRootState> {
    getTasks: (state: ITaskStoreState) => IHDTask[] | [];
    getTaskByKey(state: ITaskStoreState): (key: string) => IHDTask | undefined;
}

export interface TaskStoreMutations extends MutationTree<ITaskStoreState> {}

export interface TaskStoreActions extends ActionTree<ITaskStoreState, IRootState> {
    getTasksForAsset(context: TaskContext, assetKey: string): Promise<void>;
}

export type TaskContext = ActionContext<ITaskStoreState, IRootState>;

export const TaskStore = {
    namespaced: true as true,
    state: {
        tasks: []
    } as ITaskStoreState,
    getters:  {
        getTaskByKey: (state: ITaskStoreState) => (key: string): IHDTask | undefined => {
            return state.tasks?.find((task: IHDTask) => task.taskKey === key);
        },
        getTasks: (state: ITaskStoreState): IHDTask[] | null => {
            return state.tasks;
        }
    } as TaskStoreGetters,
    mutations: {

    } as TaskStoreMutations,
    actions: {
        async getTasksForAsset(context: TaskContext, assetKey: string): Promise<void> {
            try {
                const taskService: AssetTaskService = await initTaskLogService();
                context.state.tasks = await taskService.getTaskList(assetKey);
            } catch (error) {
                // Catch 404s and mimick to "no tasks found" response.  Else throw error
                if (error.response.status === 404) {
                    context.state.tasks = [];
                } else {
                    throw error;
                }
            }
        }
    } as TaskStoreActions
};
