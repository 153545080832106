














import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import moment from 'moment';
import Icon from './components/common/Icon.vue';
import Sprite from './components/common/Sprite.vue';
import Error from './components/modals/Error.vue';
import Logs from './components/logging/Logs.vue';
import { authGuard } from './services/auth/auth';
import {
  plEventBus,
  plEvents,
  plExternalEvents,
} from './services/eventBus/product-logging-event-bus';
import store, { VuexModuleNamespaces } from '@/store/';
import { AppStore } from '@/store/app/appStore';
import Loading from './components/common/Loading.vue';
import i18n from '@/i18n';
import { UserStore } from '@/store/user/userStore';
import {elasticRum} from '@/plugins/elastic-rum';

(async function initializeApp() {
  elasticRum();
})();
Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.config.silent = true;

Vue.filter('formatDateLongWithoutTime', (value: string) => {
  return value ? moment(Date.parse(value)).format('MMMM D, YYYY') : '-';
});

Vue.filter('formatDateLong', (value: number) => {
  return value ? moment(String(value)).format('MMMM D, YYYY [at] h:mm a') : '-';
});

Vue.filter('formatDateShort', (value: number) => {
  return value ? moment(String(value)).format('MM/DD/YY h:mm a') : '-';
});

Vue.filter('formatNumberForDisplay', (value: number) => {
  if (value === -999999 || value === undefined || value === null) {
    return '-';
  } else {
    return value.toFixed(2);
  }
});

Vue.filter('formatFirstLetterCapitalized', (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
  } else {
    return value;
  }
});

const routes = [{ path: '*', component: Logs, name: 'Logs' }];

export const productLoggingRouter = new VueRouter({
  mode: 'history',
  routes,
});

productLoggingRouter.beforeEach(authGuard);

Vue.component('Icon', Icon);

declare module 'vue/types/vue' {
  interface Vue {
    flags: { [key: string]: boolean };
    flagUpdates: (changes: any) => void;
    flagCheck: (flag: string, defaultValue: boolean) => boolean;
  }
}

@Component({
  name: 'app',
  components: {
    Error,
    Loading,
    Logs,
    Sprite,
  },
  router: productLoggingRouter,
  i18n
})
export default class ProductLogging extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public assetkey!: string;
  @Prop({ required: false })
  public burnerkey!: string;
  @Prop({ required: true })
  public logsmode!: string;
  @Prop({ required: false })
  public parentapp!: string;
  @Prop({ required: true })
  public customerAssetDetails!: string;

  // Lifecycle hooks...
  private async created() {
    // Clear leftover listeners, if present.
    this.clearPlEventBus();
    // Set the logs mode prior to keys so that productKey can be set depending on logsMode
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateAssetKey.name}`,
      this.assetkey
    );
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateBurnerKey.name}`,
      this.burnerkey
    );
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateParentApp.name}`,
      this.parentapp
    );
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateLogsMode.name}`,
      this.logsmode
    );
    // Listen for route changes
    this.$router.afterEach(() => {
      store.commit(
        `${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`,
        false
      );
    });

    // emit various events from PL for various actions occured
    plEventBus.$on(plEvents.commentEditorShown, () => {
      this.$emit(plExternalEvents.plCommentModeStarted);
    });

    plEventBus.$on(plEvents.commentEditorClosed, () => {
      this.$emit(plExternalEvents.plCommentModeEnded);
    });

    plEventBus.$on(plEvents.replyEditorShown, () => {
      this.$emit(plExternalEvents.plCommentModeStarted);
    });

    plEventBus.$on(plEvents.productFlagUpdated, async () => {
      this.$emit(plExternalEvents.plProductLogFlagUpdated);
    });

    plEventBus.$on(
      plEvents.activeCommentingModeChanged,
      async (value: boolean) => {
        this.$emit(plExternalEvents.plInActiveCommentingMode, value);
      }
    );
  }

  private mounted() {
    // Emit the setProductKey here so that ProductLogs can listen to it.
    const productKey = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.productKey.name}`];
    plEventBus.$emit(plEvents.setProductKey, productKey);

    // Handle route changes from parent
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$on(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
    this.onCustomerOrAssetChange();
  }

  private beforeDestroy() {
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }

    // unsubscribe to all the PL events before the app is destroyed
    this.clearPlEventBus();
    store.commit('burner/clearBurnersList');
  }

  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?assets\/heater\//)) {
      store.commit(
        `${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`,
        false
      );
    }
  }

  private clearPlEventBus() {
    plEventBus.$off();
  }

  get isLoading() {
    return store.getters[
      `${VuexModuleNamespaces.app}/${AppStore.getters.isLoading.name}`
    ];
  }

  @Watch('assetkey')
  private onAssetKeyChange() {
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateAssetKey.name}`,
      this.assetkey
    );
    // Emit the setProductKey on key change so that ProductLogs can re-initialize.  If
    // an asset key changes, then this is the product key.
    const productKey = this.assetkey;
    plEventBus.$emit(plEvents.setProductKey, productKey);
  }

  @Watch('burnerkey')
  private onBurnerKeyChange() {
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateBurnerKey.name}`,
      this.burnerkey
    );
    // Emit the setProductKey on key change so that ProductLogs can re-initialize.  If
    // an burner key changes, then this is the product key.
    const productKey = this.burnerkey;
    plEventBus.$emit(plEvents.setProductKey, productKey);
  }

  @Watch('logsmode')
  private onLogsModeChange() {
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateLogsMode.name}`,
      this.logsmode
    );
  }

  @Watch('parentapp')
  private onParentAppChange() {
    store.commit(
      `${VuexModuleNamespaces.app}/${AppStore.mutations.updateParentApp.name}`,
      this.parentapp
    );
  }

  @Watch('customerAssetDetails', {​ immediate: true, deep: true }​​)
  public onCustomerOrAssetChange(): void {
    if (this.customerAssetDetails) {
      store.commit(`${VuexModuleNamespaces.user}/${UserStore.mutations.updateCustomerAssetInfo.name}`, JSON.parse(this.customerAssetDetails));
    }
  }

}
