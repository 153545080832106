import { AxiosInstance, Method } from 'axios';

export default class HttpClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly baseUri: string
  ) {
  }

  /**
   * Performs an axios GET request on the provided uri
   * @param uri - The uri to perform GET request on
   * (NOTE: Do not prepend the uri with slash('/') because it overrides the default base URL)
   * @param query - The query to append to axios config
   * @returns The response data back from the request of generic type
   */
  public async get<T>(uri: string, query?: {}): Promise<T> {
    const config = query ? { params: query } : undefined;
    return (await this.axios.get<T>(this.uri(uri), config)).data;
  }

  public async post<T>(uri: string, payload?: any): Promise<T> {
    return (await this.axios.post<T>(this.uri(uri), payload)).data;
  }

  public async put<T>(uri: string, payload?: any): Promise<T> {
    return (await this.axios.put<T>(this.uri(uri), payload)).data;
  }

  public async delete<T>(uri: string): Promise<T> {
    return (await this.axios.delete<T>(this.uri(uri))).data;
  }

  public async request<T>(method: Method, uri: string, data?: any) {
    return (await this.axios.request<T>({ data, method, url: uri })).data;
  }

  protected uri(uri?: string) {
    return uri ? `${this.baseUri}/${uri}` : this.baseUri;
  }
}
