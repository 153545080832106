






































import { Vue, Component } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store/';
import { ErrorStore } from '@/store/error/errorStore';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'error'
})
export default class Error extends Vue {

  private store = store;

  get error() {
    return store.getters[`${VuexModuleNamespaces.error}/${ErrorStore.getters.getError.name}`];
  }

  private onClose() {
    // Handle Error
    if (store.getters[`${VuexModuleNamespaces.error}/${ErrorStore.getters.getRouteHomeAfterError.name}`]) {
      this.$router.push('/');
    }
    // Clear Error
    store.commit(`${VuexModuleNamespaces.error}/${ErrorStore.mutations.clearError.name}`);
  }

}
