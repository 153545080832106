import { getAuthToken } from '@/services/auth/auth';
import { UserStore } from '@/store/user/userStore';
import { AppStore } from '@/store/app/appStore';
import { BurnerStore } from '@/store/burner/burnerStore';

const authInterceptor = async (config: any) => {
  const authorization = 'Authorization';
  const correlationId = 'OnPoint-Correlation-Id';
  const medaData = 'OnPoint-MetaData';
  const userId = UserStore.state && UserStore.state.currentUser ? UserStore.state.currentUser.key : '';
  const timeStamp = AppStore.state && AppStore.state.actionTime ? AppStore.state.actionTime : '';
  let metadata = {};
  if (UserStore.state && UserStore.state.customerAssetInfo) {
    metadata = UserStore.state.customerAssetInfo;
  } else {
    metadata = {
      customerId: UserStore.state && UserStore.state.currentUser ? UserStore.state.currentUser.activeCustomerKey : '',
      customerName: '',
      assetId: BurnerStore.state && BurnerStore.state.burners ? BurnerStore.state.burners.assetKey : '',
      assetName: ''
    };
  }
  const token = await getAuthToken();
  // Do not add auth token when uploading direct to S3
  if (config.url.indexOf('s3.amazonaws.com') > -1) {
    return config;
  } else {
    config.headers[authorization] = `Bearer ${token}`;
    if (!config.baseURL.includes('hierarchy-builder')) {
      config.headers[correlationId] = `EL|${userId}|${timeStamp}`;
      config.headers[medaData] = btoa(JSON.stringify(metadata));
    }
    return config;
  }
};

export default authInterceptor;
