import store, { IRootState, VuexModuleNamespaces } from '../../store';
import EntityLogService from '@/services/entityLogs/entity-log-service';
import { LogsMode, AppStore } from '@/store/app/appStore';
import sharedAxiosInstance from '@/services/common/api-service';
import {
  IProductLogResponse,
  IProductLogMediaViewModel,
  IAddProductLogRequest,
  IProductLogRequest,
  IProductLogMediaCache,
  IProductLogFlagUpdateRequest,
  IProductLogs,
  IEntityLogResponse,
  IAddEntityLogRequest,
  IEntityLogFlagUpdateRequest,
  IDeleteEntityLogRequest,
} from '@/view-models/productLog/product-log-view-models';

import ConfigFactory from '@/services/config/config';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { BurnerStore } from '../burner/burnerStore';
import { ErrorStore } from '../error/errorStore';
import { TaskStore } from '../task/taskStore';
import LoggerService from '@/services/logger/logger-service';
import { ILog, LogFeature } from '@/view-models/common/log-model';
import { generateLoggerPayload, initLoggerService } from '@/utils/logs-helper';

const initEntityLogService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new EntityLogService(
    process.env.VUE_APP_ENTITY_LOGS_API_BASE_URL
      ? process.env.VUE_APP_ENTITY_LOGS_API_BASE_URL
      : conf.get('entityLogsUrl'),
    sharedAxiosInstance
  );
};

export interface IProductLogStoreState {
  attachedMedias: IProductLogMediaViewModel[];
  productLogMedia: IProductLogMediaViewModel;
  productLogResponse: IProductLogResponse;
  productLogText: string;
  isBurnerSelected: boolean;
  mediaCaches: IProductLogMediaCache[];
}

export interface IProductLogStoreActions
  extends ActionTree<IProductLogStoreState, IRootState> {
  addLog(
    context: IProductLogContext,
    data: IAddProductLogRequest
  ): Promise<void>;
  updateLog(
    context: IProductLogContext,
    data: IAddProductLogRequest
  ): Promise<void>;
  deleteLog(
    context: IProductLogContext,
    data: IDeleteEntityLogRequest
  ): Promise<void>;
  downloadMedia(
    context: IProductLogContext,
    request: IDownloadMediaRequest
  ): Promise<string | void>;
  retrieveProductLogs(
    context: IProductLogContext,
    request: IProductLogRequest
  ): Promise<IProductLogResponse | void>;
  updateProductLogFlag(
    context: IProductLogContext,
    request: IProductLogFlagUpdateRequest
  ): Promise<IProductLogs | void>;
  uploadMedia(
    context: IProductLogContext,
    productKey: string
  ): Promise<IProductLogUploadMediaApiDto | void>;
}

export interface IProductLogStoreGetters
  extends GetterTree<IProductLogStoreState, IRootState> {
  isBurnerSelected(state: IProductLogStoreState): boolean;
  productLogText(state: IProductLogStoreState): string;
}

export interface IProductLogStoreMutations
  extends MutationTree<IProductLogStoreState> {
  clearMediaCaches(
    state: IProductLogStoreState
  ): void;
  setAttachedMedias(
    state: IProductLogStoreState,
    data: IProductLogMediaViewModel[]
  ): void;
  setProductLogMedia(
    state: IProductLogStoreState,
    data: IProductLogMediaViewModel
  ): void;
  updateIsBurnerSelected(
    state: IProductLogStoreState,
    newIsBurnerSelected: boolean
  ): void;
  setProductLogText(
    state: IProductLogStoreState,
    text: string
  ): void;
  updateMediaCaches(
    state: IProductLogStoreState,
    mediaCache: IProductLogMediaCache
  ): void;
}

export interface IProductLogUploadMediaApiDto {
  imageKey: string;
  preSignedUrl: string;
  s3Path?: string;
}

export interface IEntityLogUploadMediaApiDto {
  imageKey?: string; // used to map to legacy attributes
  mediaKey: string;
  preSignedUrl: string;
  s3Path?: string;
}

export interface IDownloadMediaRequest {
  mediaKey: string;
  key: string;
}

export interface IProductLogUploadRequest{
  assetKey: string|null;
  key: string;
}

export interface IProductLogDownloadRequest{
  mediaKey: string;
  key: string;
  assetKey: string|null;
}

export interface IProductLogDeleteRequest {
  entityKey: string;
  logKey: string;
  assetKey: string|null;
}

export type IProductLogContext = ActionContext<IProductLogStoreState, IRootState>;

export const ProductLogStore = {
  namespaced: true as true,
  state: {
    productLogText: '',
    isBurnerSelected: false,
    mediaCaches: Array<IProductLogMediaCache>(),
    attachedMedias: Array<IProductLogMediaViewModel>()
  } as IProductLogStoreState,
  getters: {
    isBurnerSelected(state: IProductLogStoreState): boolean {
      return state.isBurnerSelected;
    },
    productLogText(state: IProductLogStoreState): string {
      return state.productLogText;
    }
  } as IProductLogStoreGetters,
  mutations: {
    clearMediaCaches(state: IProductLogStoreState) {
      state.mediaCaches = [];
    },
    setAttachedMedias(
      state: IProductLogStoreState,
      data: IProductLogMediaViewModel[]
    ) {
      state.attachedMedias = data;
    },
    setProductLogMedia(
      state: IProductLogStoreState,
      data: IProductLogMediaViewModel
    ) {
      state.productLogMedia = data;
    },
    updateIsBurnerSelected(
      state: IProductLogStoreState,
      newIsBurnerSelected: boolean
    ) {
      state.isBurnerSelected = newIsBurnerSelected;
    },
    setProductLogText(
      state: IProductLogStoreState,
      text: string
    ) {
      state.productLogText = text;
    },
    updateMediaCaches(
      state: IProductLogStoreState,
      mediaCache: IProductLogMediaCache
    ) {
      state.mediaCaches.push(mediaCache);
    }
  } as IProductLogStoreMutations,
  actions: {
    async addLog(context: IProductLogContext, data: IAddProductLogRequest): Promise<void> {
      const logsMode: LogsMode | null = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
      let entityLogRequest: IAddEntityLogRequest = {
        parentEntityKey: data.assetKey,
        parentLogKey: data.parentKey,
        isFlagged: data.isFlagged,
        text: data.text,
        media: data.logImages,
        logType: data.logType
      } as IAddEntityLogRequest;
      switch (logsMode) {
        case LogsMode.BURNER:
          entityLogRequest.entityKey = data.burnerKey ? data.burnerKey : '';
          entityLogRequest.entityType = 'Burner';
          entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.burnerKey;
          break;
        case LogsMode.ASSET:
        case LogsMode.TOWER:
          if (data.burnerKey) {
            entityLogRequest.entityKey = data.burnerKey;
            entityLogRequest.entityType = 'Burner';
            entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.burnerKey;
          } else {
            entityLogRequest.entityKey = data.assetKey;
            entityLogRequest.entityType = 'Asset';
            entityLogRequest.entityAncestorKey = data.assetKey;
          }
          break;
        default:
      }
      entityLogRequest.media.forEach((mediaItem) => {
        mediaItem.mediaKey = mediaItem.imageKey;
        delete mediaItem.imageKey;
      });
      const entityLogService: EntityLogService = await initEntityLogService();
      await entityLogService.addLog(entityLogRequest).then(async () => {
        const loggerService: LoggerService = await initLoggerService();
        let payload;
        const additionalData = {
          assetName: data.assetKey
        };
        if (entityLogRequest.logType === 'Reply') {
          const message = LogFeature['EL-LogsReply'] + store.state.app.assetKey;
          payload = generateLoggerPayload(message, 'EL-LogsReply', additionalData);
        } else {
          const message = LogFeature['EL-CreateLogs'] + store.state.app.assetKey;
          payload = generateLoggerPayload(message, 'EL-CreateLogs', additionalData);
        }
        await loggerService.addLogs(payload);
      });
    },
    async updateLog(context: IProductLogContext, data: IAddProductLogRequest): Promise<void> {
      const logsMode: LogsMode | null = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
      let entityLogRequest: IAddEntityLogRequest = {
        logKey: data.key ? data.key : '',
        parentEntityKey: data.assetKey,
        parentLogKey: data.parentKey,
        isFlagged: data.isFlagged,
        text: data.text,
        media: data.logImages,
        logType: data.logType
      } as IAddEntityLogRequest;
      switch (logsMode) {
        case LogsMode.BURNER:
          entityLogRequest.entityKey = data.burnerKey ? data.burnerKey : '';
          entityLogRequest.entityType = 'Burner';
          entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.burnerKey;
          break;
        case LogsMode.ASSET:
        case LogsMode.TOWER:
          if (data.burnerKey) {
            entityLogRequest.entityKey = data.burnerKey;
            entityLogRequest.entityType = 'Burner';
            entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.burnerKey;
          } else if (data.taskKey) {
            entityLogRequest.entityKey = data.taskKey;
            entityLogRequest.entityType = 'Task';
            entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.taskKey;
          } else {
            entityLogRequest.entityKey = data.assetKey;
            entityLogRequest.entityType = 'Asset';
            entityLogRequest.entityAncestorKey = data.assetKey;
          }
          break;
        case LogsMode.TASK:
          if (data.taskKey) {
            entityLogRequest.entityKey = data.taskKey;
            entityLogRequest.entityType = 'Task';
            entityLogRequest.entityAncestorKey = data.assetKey + '#' + data.taskKey;
          }
          break;
        default:
      }
      entityLogRequest.media.forEach((mediaItem) => {
        mediaItem.mediaKey = mediaItem.imageKey;
        delete mediaItem.imageKey;
      });
      const entityLogService: EntityLogService = await initEntityLogService();
      await entityLogService.updateLog(entityLogRequest).then(async () => {
        const loggerService: LoggerService = await initLoggerService();
        const additionalData = {
          assetName: data.assetKey
        };
        const payload = generateLoggerPayload(LogFeature['EL-UpdateLogs'], 'EL-UpdateLogs', additionalData);
        await loggerService.addLogs(payload);
      });
    },
    async deleteLog(context: IProductLogContext, data: IDeleteEntityLogRequest): Promise<void> {
      const entityLogService: EntityLogService = await initEntityLogService();
      let request : IProductLogDeleteRequest = {
        entityKey : data.entityKey,
        logKey : data.logKey,
        assetKey : store.state.app.assetKey ?? ''
      };
      await entityLogService.deleteLog(request).then(async () => {
        const loggerService: LoggerService = await initLoggerService();
        const additionalData = {
          assetName: store.state.app.assetKey ?? ''
        };
        const message = LogFeature['EL-DeleteLogs'] + store.state.app.assetKey;
        const payload = generateLoggerPayload(message, 'EL-DeleteLogs', additionalData);
        await loggerService.addLogs(payload);
      });
    },
    async downloadMedia(context: IProductLogContext, request: IDownloadMediaRequest): Promise<string | void> {
      const entityLogService: EntityLogService = await initEntityLogService();
      let data : IProductLogDownloadRequest = {
        mediaKey : request.mediaKey,
        key : request.key,
        assetKey : store.state.app.assetKey ?? ''
      };
      const media: string = await entityLogService.downloadMedia(data);
      context.commit(ProductLogStore.mutations.setProductLogMedia.name, media);
      return media;
    },
    async updateProductLogFlag(context: IProductLogContext, request: IProductLogFlagUpdateRequest): Promise<void> {
      const logsMode: LogsMode | null = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
      let flagRequest: IEntityLogFlagUpdateRequest = {
        logKey: request.logKey,
        isFlagged: request.isFlagged,
        assetKey: store.state.app.assetKey ?? ''
      } as IEntityLogFlagUpdateRequest;
      if (logsMode && logsMode === LogsMode.BURNER) {
        flagRequest.entityKey = request.burnerKey;
      } else if (logsMode && (logsMode === LogsMode.ASSET || logsMode === LogsMode.TOWER)) {
        flagRequest.entityKey = request.burnerKey ? request.burnerKey : request.assetKey;
      }
      const entityLogService: EntityLogService = await initEntityLogService();
      await entityLogService.updateProductLogFlag(flagRequest).then(async () => {
        const loggerService: LoggerService = await initLoggerService();
        const additionalData = {
          assetName: request.assetKey
        };
        const message = LogFeature['EL-LogsFlagged'] + store.state.app.assetKey;
        const payload = generateLoggerPayload(message, 'EL-LogsFlagged', additionalData);
        await loggerService.addLogs(payload);
      });
    },
    async retrieveProductLogs(context: IProductLogContext, request: IProductLogRequest): Promise<IProductLogResponse | IEntityLogResponse | void> {
      const logsMode: LogsMode | null = store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
      let retrievedLogs = {} as IEntityLogResponse;
      if (logsMode && logsMode === LogsMode.BURNER) {
        const entityLogService: EntityLogService = await initEntityLogService();
        await entityLogService.getLogs(request).then(async (resp) => {
          retrievedLogs = resp;
          const loggerService: LoggerService = await initLoggerService();
          const additionalData = {
            assetKey: store.state.app.assetKey ?? ''
          };
          const message = LogFeature['EL-LogsFilter'] + request.searchTerm ?? '';
          const payload = generateLoggerPayload(message, 'EL-LogsFilter', additionalData);
          await loggerService.addLogs(payload);
        });
        retrievedLogs.data.forEach((entityLog) => {
          // Set burner and asset attributes
          if (entityLog.entityType && entityLog.entityType.toUpperCase() === 'BURNER') {
            entityLog.burnerKey = entityLog.entityKey;
            entityLog.assetKey = entityLog.parentEntityKey;
            entityLog.burnerName = store.getters[`${VuexModuleNamespaces.burner}/${BurnerStore.getters.getBurnerByKey.name}`](entityLog.burnerKey);
          } else {
            entityLog.assetKey = entityLog.entityKey;
          }
          // Set legacy key attributes required for Product Logs display logic
          entityLog.parentKey = entityLog.parentLogKey;
          entityLog.key = entityLog.logKey;
          entityLog.lastEditedDate = entityLog.lastEditedAt;
          entityLog.createdDate = entityLog.createdAt;
          entityLog.lastModifiedDate = entityLog.lastModifiedAt;
          entityLog.logImages = entityLog.media;
          entityLog.logImages.forEach((logImage) => {
            logImage.imageKey = logImage.mediaKey;
          });
        });
        return retrievedLogs;
      } else if (logsMode && (logsMode === LogsMode.ASSET || logsMode === LogsMode.TOWER)) {
        const entityLogService: EntityLogService = await initEntityLogService();
        await entityLogService.getLogs(request).then(async (resp) => {
          retrievedLogs = resp;
          const loggerService: LoggerService = await initLoggerService();
          const additionalData = {
            assetKey: store.state.app.assetKey ?? ''
          };
          const message = LogFeature['EL-LogsFilter'] + request.searchTerm ?? '';
          const payload = generateLoggerPayload(message, 'EL-LogsFilter', additionalData);
          await loggerService.addLogs(payload);
        });
        // If no burner data, then retrieve this
        if (store.getters[`${VuexModuleNamespaces.burner}/${BurnerStore.getters.getBurners.name}`] === null
        && logsMode !== LogsMode.TOWER) {
          await store.dispatch(
            `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
            {
              action: async () => {
                await store.dispatch(`${VuexModuleNamespaces.burner}/${BurnerStore.actions.getBurnersForAsset.name}`,
                store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.assetKey.name}`]);
              },
              errorMsg: 'Error retrieving burner information for current asset: ',
            }
          );
        }
        retrievedLogs.data.forEach((entityLog) => {
          // Find burner
          const burner = store.getters[`${VuexModuleNamespaces.burner}/${BurnerStore.getters.getBurnerByKey.name}`](
            entityLog.entityKey);
          const task = store.getters[`${VuexModuleNamespaces.task}/${TaskStore.getters.getTaskByKey.name}`](
              entityLog.entityKey);
          // Set burner and asset attributes
          if (entityLog.entityType && entityLog.entityType.toUpperCase() === 'BURNER') {
            entityLog.burnerKey = entityLog.entityKey;
            entityLog.assetKey = entityLog.parentEntityKey;
            entityLog.burnerName = burner ? burner.burnerName : `Unknown Burner (${entityLog.entityKey})`;
          } else if (entityLog.entityType && entityLog.entityType.toUpperCase() === 'TASK') {
            entityLog.taskKey = entityLog.entityKey;
            entityLog.assetKey = entityLog.parentEntityKey;
            entityLog.taskName = task ? task.taskName : `Unknown Task (${entityLog.entityKey})`;
          } else {
            entityLog.assetKey = entityLog.entityKey;
          }
          // Set legacy key attributes required for Product Logs display logic
          entityLog.parentKey = entityLog.parentLogKey;
          entityLog.key = entityLog.logKey;
          entityLog.lastEditedDate = entityLog.lastEditedAt;
          entityLog.createdDate = entityLog.createdAt;
          entityLog.lastModifiedDate = entityLog.lastModifiedAt;
          entityLog.logImages = entityLog.media;
          entityLog.logImages.forEach((logImage) => {
            logImage.imageKey = logImage.mediaKey;
          });
        });
        return retrievedLogs;
      } else if (logsMode && logsMode === LogsMode.TASK) {
        const entityLogService: EntityLogService = await initEntityLogService();
        await entityLogService.getLogs(request).then(async (resp) => {
          retrievedLogs = resp;
          const loggerService: LoggerService = await initLoggerService();
          const additionalData = {
            assetKey: store.state.app.assetKey ?? ''
          };
          const message = LogFeature['EL-LogsFilter'] + request.searchTerm ?? '';
          const payload = generateLoggerPayload(message, 'EL-LogsFilter', additionalData);
          await loggerService.addLogs(payload);
        });
        retrievedLogs.data.forEach((entityLog) => {
          // Find task
          const task = store.getters[`${VuexModuleNamespaces.task}/${TaskStore.getters.getTaskByKey.name}`](
            entityLog.entityKey);
          // Set task and asset attributes
          if (entityLog.entityType && entityLog.entityType.toUpperCase() === 'TASK') {
            entityLog.taskKey = entityLog.entityKey;
            entityLog.assetKey = entityLog.parentEntityKey;
            entityLog.taskName = task ? task.taskName : `Unknown Task (${entityLog.entityKey})`;
          } else {
            entityLog.assetKey = entityLog.entityKey;
          }
          // Set legacy key attributes required for Product Logs display logic
          entityLog.parentKey = entityLog.parentLogKey;
          entityLog.key = entityLog.logKey;
          entityLog.lastEditedDate = entityLog.lastEditedAt;
          entityLog.createdDate = entityLog.createdAt;
          entityLog.lastModifiedDate = entityLog.lastModifiedAt;
          entityLog.logImages = entityLog.media;
          entityLog.logImages.forEach((logImage) => {
            logImage.imageKey = logImage.mediaKey;
          });
        });
        return retrievedLogs;
      }
    },

    async uploadMedia(
      context: IProductLogContext,
      productKey: string
    ): Promise<IProductLogUploadMediaApiDto | IEntityLogUploadMediaApiDto | void> {
      const entityLogService: EntityLogService = await initEntityLogService();
      let responseData;
      let request: IProductLogUploadRequest = {
        assetKey: store.state.app.assetKey ?? '',
        key: productKey
      };
      await entityLogService.uploadMedia(request).then(async (resp) => {
        responseData = resp;
        responseData.imageKey = responseData.mediaKey;
        const loggerService: LoggerService = await initLoggerService();
        const additionalData = {
          assetKey: store.state.app.assetKey ?? ''
        };
        const message = LogFeature['EL-UploadMedia'] + store.state.app.assetKey;
        const logPayload: ILog = generateLoggerPayload(message, 'EL-UploadMedia', additionalData);
        await loggerService.addLogs(logPayload);
      });
      return responseData;
    },
  } as IProductLogStoreActions,
};
