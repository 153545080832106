






























import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import MediaLibraryModal from '@/components/modals/MediaLibraryModal.vue';
import {BootstrapVue} from 'bootstrap-vue';
import store, {VuexModuleNamespaces} from '@/store/';
import {ProductLogStore} from '@/store/productLog/productLogStore';
import {ProductLogMediaLibraryStore} from '@/store/productLogMediaLibrary/productLogMediaLibraryStore';
import {plEventBus, plEvents} from '@/services/eventBus/product-logging-event-bus';
import {ErrorStore} from '@/store/error/errorStore';
import {
  ConfirmDiscardContext,
  IAddProductLogRequest,
  IProductLogMediaViewModel,
  IProductLogs
} from '@/view-models/productLog/product-log-view-models';
import {AppStore, LogsMode} from '@/store/app/appStore';
import { TransactionName } from '@/enums/transaction-name';
import BaseComponent from '../common/BaseComponent';

Vue.use(BootstrapVue);

@Component({
  name: 'CommentEditor',
  components: {
    MediaLibraryModal,
  }
})
export default class CommentEditor extends BaseComponent {

  // VUE.JS Props...
  @Prop({ required: true })
  public itemKey!: string;
  @Prop()
  public parentKey?: string;
  @Prop()
  public log?: IProductLogs;
  @Prop()
  public edit?: boolean;

  // Properties...
  public mediaAttachMessage: string = '';
  public commentText: string = '';
  public attachedMedias: IProductLogMediaViewModel[] = [];
  public flaggedComment: boolean = false;
  public sendingCommentRequest: boolean = false;
  public isLoading: boolean = false;

  private ConfirmDiscardContext = ConfirmDiscardContext;

  public isTowerLogMode(): boolean {
    const logsMode: LogsMode | null =
        store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
    return logsMode === LogsMode.TOWER;
  }
  // Lifecycle hooks...
  public destroyed() {
    store.commit(
        `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setAttachedMedias.name}`, []);
    store.commit(
        `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setProductLogText.name}`, '');
  }

  public created() {

    this.isLoading = false;

    plEventBus.$on(plEvents.mediaLibraryModalClosed, () => {
      this.updateMediaAttachMessage();
    });

    if (this.log && this.edit) {
      this.commentText = this.log.text;
      store.commit(
          `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setProductLogText.name}`, this.log.text);
      this.flaggedComment = this.log.isFlagged;
      // make sure the message displaying attached medias is accurate:
      store.commit(
          `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setAttachedMedias.name}`, this.log.logImages);
      this.updateMediaAttachMessage();
      store.commit(`${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.setMedias.name}`,
        ProductLogStore.state.attachedMedias);
    }
  }

  public mounted() {
    const commentTextbox = this.$refs.commentTextbox as HTMLElement;
    commentTextbox.focus();
    plEventBus.$emit(plEvents.contentScrollable, false);

    this.$el.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    });
  }

  public toggleFlagComment() {
    this.flaggedComment = !this.flaggedComment;
  }

  // Event Methods...
  public async addLog() {
    // Prevent blank (or only whitespace) comments
    if (this.commentText.trim().length === 0) {
      return;
    }

    // set new action time to be sent as header
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);

    // Initialize app state for adding a log
    this.isLoading = true;
    plEventBus.$emit(plEvents.tryAddorEditComment);
    plEventBus.$emit(plEvents.commentEditorClosed);

    // Customize request based on logs mode
    const productLog: IAddProductLogRequest = {
      assetKey: store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.assetKey.name}`],
      isFlagged: this.flaggedComment,
      logType: 'Log',
      text: this.commentText,
      logImages: this.attachedMedias
    };
    // In "Asset" logs mode, add the burner key only if present in the log object
    const logsMode: LogsMode | null =
      store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
    if (logsMode && (logsMode === LogsMode.ASSET || logsMode === LogsMode.TOWER)) {
      if (this.log && 'burnerKey' in this.log) {
        productLog.burnerKey = this.log.burnerKey;
      }
    }
    // In "Burner" logs mode, always add the burner key
    if (logsMode && logsMode === LogsMode.BURNER) {
      productLog.burnerKey = this.itemKey;
    }
    // Adjust request if this is a reply
    if (this.parentKey) {
      productLog.parentKey = this.parentKey;
      productLog.logType = 'Reply';
    }

    // Send log request
    if (this.log && this.edit) {
      // This is an edit, call the updateLog method
      productLog.key = this.log.key;
      if (this.log.entityType === LogsMode.TASK) {
        productLog.taskKey = this.log.entityKey;
      }
      await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
        action: async () => store.dispatch(
          `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.updateLog.name}`, productLog),
        errorMsg: 'Error editing comment ',
        routeHomeAfterError: false
      });
    } else {
      // This is a new log, call the addLog method
      await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`, {
        action: async () => store.dispatch(
          `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.addLog.name}`, productLog),
        errorMsg: 'Error posting comment ',
        routeHomeAfterError: false
      });
      this.stopTransaction(TransactionName.NewLog);
    }

    // Clean up app state
    this.closeCommentEditor();
    plEventBus.$emit(plEvents.commentAddedOrDeleted);
    this.isLoading = false;
  }

  public openMediaLibrary() {
    this.$bvModal.show('media-library-modal');
  }

  private closeCommentEditor() {
    this.$emit('clear-logs-editor', true);
  }

  // Private Methods...
  private updateMediaAttachMessage(): void {
    let attachMessage: string = '';
    this.attachedMedias = ProductLogStore.state.attachedMedias;

    if (this.attachedMedias != null && this.attachedMedias.length > 0) {
      let selectedPhotosCount: number = 0;
      let selectedVideosCount: number = 0;

      this.attachedMedias.forEach((attachment) => {
        if (attachment.isVideo) {
          selectedVideosCount++;
        } else {
          selectedPhotosCount++;
        }
      });

      if (selectedPhotosCount > 0) {
        attachMessage += `${selectedPhotosCount} ${selectedPhotosCount === 1 ? 'photo' : 'photos'}`;
      }

      if (selectedVideosCount > 0) {
        if (selectedPhotosCount > 0) {
          attachMessage += '/';
        }

        attachMessage += `${selectedVideosCount} ${
          selectedVideosCount === 1 ? 'video' : 'videos'
        }`;
      }

      attachMessage += ' attached';
    }

    this.mediaAttachMessage = attachMessage;
  }

  private updateStoreTextValue() {
    store.commit(
          `${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setProductLogText.name}`, this.commentText);
  }

   // Watchers...
  @Watch('commentText')
  private onCommentTextChange() {
    let isInActiveCommentingMode: boolean = false;
    if (this.commentText.trim() !== '' || this.attachedMedias.length > 0) {
       isInActiveCommentingMode = true;
    }

    plEventBus.$emit(plEvents.activeCommentingModeChanged, isInActiveCommentingMode);
  }

  @Watch('attachedMedias')
  private onAttachedMediasChange() {
    let isInActiveCommentingMode = this.commentText.trim() !== '' || this.attachedMedias.length > 0;
    plEventBus.$emit(plEvents.activeCommentingModeChanged, isInActiveCommentingMode);
  }
}
