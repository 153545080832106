export interface IUser {
    key: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    isNewPasswordRequired: boolean;
    isPasswordResetRequired: boolean;
    refreshToken: string;
    token: string;
    activeCustomerKey: string;
    orgName: string;
    isSuperUser?: boolean;
    roleKeys?: string[];
    permissions?: Permission[];
    isAdminCompanyUser?: boolean;
}

export enum Permission {
    Unknown = 'Unknown',
    CreateUser = 'CreateUser',
    ManageUsers = 'ManageUsers',
    SetUserAssignments = 'SetUserAssignments',
    ReadBurnerStatus = 'ReadBurnerStatus',
    WriteBurnerStatus = 'WriteBurnerStatus',
    ManageCustomers = 'ManageCustomers',
    ReadHeaters = 'ReadHeaters',
    CreateHeaters = 'CreateHeaters',
    ManageHeaters = 'ManageHeaters',
    DeleteLogs = 'DeleteLogs',
    ManageKESCompanies = 'ManageKESCompanies',
    ShowRecommendationOverride = 'ShowRecommendationOverride',
    KESPortalAccess = 'KESPortalAccess',
    ViewAssetDiagram = 'ViewAssetDiagram',
    CreateAssetDiagram = 'CreateAssetDiagram',
    ViewReports = 'ViewReports',
    ManageReports = 'ManageReports',
    ViewDashboards = 'ViewDashboards',
    ManageDashboards = 'ManageDashboards',
    ManagePerformance = 'ManagePerformance',
    ViewPerformance = 'ViewPerformance',
    CreateHydraulicModel = 'CreateHydraulicModel',
    ViewHydraulicModel = 'ViewHydraulicModel',
    ManageAssetConfiguration = 'ManageAssetConfiguration',
    ViewAssetConfiguration = 'ViewAssetConfiguration',
}
export interface IMetadataDetails {
    customerId: string;
    customerName: string;
    assetId: string;
    assetName: string;
}
