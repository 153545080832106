import { deleteTransaction, getTransaction, setTransaction } from '@/plugins/elastic-rum';
import { apm } from '@elastic/apm-rum';
import Vue from 'vue';

export default class BaseComponent extends Vue {
  public startTransaction(transactionName: string, transactionType: string = 'custom-page-load', transactionKey: string = 'customPageLoad') {
    // Transaction key is default to cutomPageLoad, Since there will be only one page load transaction can happen at a time.
    // If we need to do multi transaction at a time we can provide
    // the trasaction key accordingly and end the transaction based on the key.
    const transaction = apm.startTransaction(transactionName, transactionType);
    // To add extra information to transaction as a key-value pair.
    // apm.addLabels({ 'testkey': 'value' });
    setTransaction(transaction, transactionKey);
  }
  public stopTransaction(transactionName: string = '', transactionKey: string = 'customPageLoad'):void {
    const transactionByKey = getTransaction(transactionKey);
    if (transactionByKey) {
      // To set or update the transaction name where it ends and it is optional.
      if (transactionName) {
        transactionByKey.name = transactionByKey.name + ' - ' + transactionName;
      }
       transactionByKey.end();
       deleteTransaction(transactionKey);
    }
  }
}