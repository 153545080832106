import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import JsonLocalStorage from '../storage/json-local-storage';

export type UrlQuery = string | Record<string, any>;

export interface IAuthClient {
  init(env: IAuthSettings): Promise<void>;
  isAuthenticated(): Promise<boolean>;
  getAuthUser(): Promise<any>;
  logOut(returnPathConfig: { saveReturnPath: boolean, clearExistingReturnPath?: boolean }): void;
  loginWithRedirect(): Promise<void>;
  handleRedirectCallback(url?: string): Promise<RedirectLoginResult>;
  smartHandlePostLogin(urlQuery: UrlQuery, onPostLogin?: () => Promise<void>): Promise<boolean>;
  getAuthToken(): Promise<string>;
  forceUpdateToken(): Promise<void>;
}

const LOCAL_STATE_NS = 'on-point.auth.state';
interface ILocalAuthState {
  returnPath?: string;
  onPostLoginNeedsToHandle: boolean;
  onPostLoginIsHandled: boolean;
}

const localAuthStateDefault: ILocalAuthState = {
  onPostLoginNeedsToHandle: false,
  onPostLoginIsHandled: true
};

function getCurrentUri() {
  return `${window.location.pathname}${window.location.search}`;
}

export enum Auth0ErrorsEnum {
  LoginRequired = 'login_required',
  ConsentRequired = 'consent_required',
}

export interface IAuthSettings {
  authDomain: string;
  authClientID: string;
  authAudience: string;
  authRedirectUri?: string;
}

class AuthClient implements IAuthClient {
  private auth0Client: Auth0Client | null = null;
  private fetchingTokenPromise: Promise<string> | null = null;
  private cache: JsonLocalStorage<ILocalAuthState> = new JsonLocalStorage<ILocalAuthState>(LOCAL_STATE_NS, localAuthStateDefault);

  private get client(): Auth0Client {
    if (this.auth0Client == null) {
      throw new Error('AuthClient has not be initialized');
    }
    return this.auth0Client;
  }

  public async init(env: IAuthSettings): Promise<void> {
    this.auth0Client = await createAuth0Client({
      domain: env.authDomain,
      client_id: env.authClientID,
      audience: env.authAudience,
      redirect_uri: window.location.origin,
    });
    this.cache = new JsonLocalStorage<ILocalAuthState>(LOCAL_STATE_NS, localAuthStateDefault);
  }

  public async isAuthenticated(): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.client.isAuthenticated();
      if (!result) {
        await this.client.getTokenSilently();
        result = await this.client.isAuthenticated();
      }
    } catch {
      return false;
    }
    return result;
  }

  public async getAuthUser(): Promise<any> {
    try {
      return await this.client.getUser();
    } catch (e) {
      return null;
    }
  }

  public logOut(returnPathConfig: { saveReturnPath: boolean, clearExistingReturnPath?: boolean }): void {
    if (returnPathConfig.saveReturnPath) {
      this.saveReturnPath();
    } else if (returnPathConfig.clearExistingReturnPath) {
      this.cache.patchUpdate({
        returnPath: '/',
      });
    }

    this.client.logout({
      returnTo: window.location.origin,
    });
  }

  public async loginWithRedirect() {
    this.saveReturnPath();
    await this.client.loginWithRedirect();
  }

  public getAuthToken(): Promise<string> {
    if (this.fetchingTokenPromise == null) {
      this.fetchingTokenPromise = this.client.getTokenSilently().finally(() => {
        this.fetchingTokenPromise = null;
      });
    }
    return this.fetchingTokenPromise;
  }

  public async forceUpdateToken(): Promise<void> {
    await this.client.getTokenSilently({ ignoreCache: true });
  }

  public async handleRedirectCallback(): Promise<RedirectLoginResult> {
    const result = await this.client.handleRedirectCallback();
    const currentUri = getCurrentUri();
    const returnPath = this.cache.state.returnPath;
    if (returnPath != null && returnPath !== '' && currentUri !== returnPath) {
      window.location.href = returnPath;
    }

    return result;
  }

  public async smartHandlePostLogin(urlQuery: UrlQuery, onPostLogin?: () => Promise<void>): Promise<boolean> {
    const runAuth0Callback: boolean = this.hasUserArrivedFromLogin(urlQuery);

    if (runAuth0Callback) {
      try {
        await this.handleRedirectCallback();
      } catch (error) {
        const status = this.grabErrorStatus(error);

        if (status === 401) {
          this.logOut({ saveReturnPath: true });
        }
        throw error;
      }
      this.cache.patchUpdate({ onPostLoginNeedsToHandle: true });
    }

    // We are using local storage cache to know if we have ran the post login process.
    // Mainly because some times there is a silent reload of page when the handleRedirectCallback()
    // method is called.
    if (this.cache.state.onPostLoginNeedsToHandle) {
      this.cache.patchUpdate({ onPostLoginIsHandled: false });
    }

    if (this.cache.state.onPostLoginNeedsToHandle && !this.cache.state.onPostLoginIsHandled) {
      if (onPostLogin != null) {
        await onPostLogin();
      }
      this.cache.patchUpdate({
        onPostLoginNeedsToHandle: false,
        onPostLoginIsHandled: true
      });
    }

    return runAuth0Callback;
  }

  //#region Helper Methods...
  
  private hasUserArrivedFromLogin(urlQuery: UrlQuery): boolean {
    if (urlQuery == null) {
      return false;
    }
    if (typeof urlQuery === 'string') {
      return urlQuery.includes('code=') && urlQuery.includes('state=');
    }

    // it is an object
    return urlQuery.code != null && urlQuery.state != null;
  }

  private grabErrorStatus(error: unknown) {
    if (error != null && typeof error === 'object' && error!.constructor === Object) {
      const parsedError = error as Record<string, any>;
      return (parsedError.response || {}).status;
    }

    return null;
  }

  private saveReturnPath() {
    if (this.cache.state.returnPath == null) {
      this.cache.patchUpdate({
        returnPath: getCurrentUri(),
      });
    }
  }

  //#endregion Helper Methods...
}

export const authClient: IAuthClient = new AuthClient();

/**
 * This is here mostly to be used by child apps
 * @param client IAuthClient
 * @param errorHandler Function
 */
export function setupCallbackHandler(client: IAuthClient, errorHandler: (error: any) => void) {
  const listener = async () => {
    try {
      const ran = await client.smartHandlePostLogin(window.location.search);
      if (ran) {
        window.removeEventListener('load', listener);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  window.addEventListener('load', listener);
}
