
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  MediaProperty,
  IProductLogMediaViewModel,
} from '@/view-models/productLog/product-log-view-models';
import store, { VuexModuleNamespaces } from '@/store/';
import { ProductLogStore, IProductLogUploadMediaApiDto } from '@/store/productLog/productLogStore';
import { ProductLogMediaLibraryStore } from '@/store/productLogMediaLibrary/productLogMediaLibraryStore';
import MediaLibraryItem from '@/components/media/MediaLibraryItem.vue';
import sharedAxiosInstance from '@/services/common/api-service';
import { ErrorStore } from '@/store/error/errorStore';
import Loading from '@/components/common/Loading.vue';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';

Vue.use(BootstrapVue);

@Component({
  name: 'media-library',
  components: {
    MediaLibraryItem,
    Loading,
  },
})
export default class MediaLibrary extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public itemKey!: string;

  // Properties...
  private maximumMediasLimit: number = 30;
  private thumbnailTargetSize: number = 64;
  private isLoading: boolean = false;
  private uploadActionCount: number = 0;

  // Getters...
  private get maximumMediaLimitReached() {
    return this.validMedias.length === this.maximumMediasLimit;
  }

  get medias(): IProductLogMediaViewModel[] {
    return store.state.productLogMediaLibrary.medias;
  }

  private get validMedias() {
    return this.medias.filter((media) => !media.isFailedPreview);
  }

  // Lifecycle Hooks...
  public created(): void {
    this.onLibraryMediaItemsSelected();
    plEventBus.$on(plEvents.libraryMediaSelected, () =>
      this.onLibraryMediaItemsSelected()
    );
  }

  // Private Methods...
  private onLibraryMediaItemsSelected(): void {
    let selectedMediasCount: number = 0;
    if (this.medias !== undefined && this.medias.length > 0) {
      selectedMediasCount = this.medias.filter((media) => media.isSelected)
        .length;
    }
    plEventBus.$emit(
      plEvents.selectedLibraryMediasCounted,
      selectedMediasCount
    );
  }

  // Event Methods...
  private initializeLoading() {
    this.isLoading = true;
    plEventBus.$emit(plEvents.uploadingMedias, true);
  }

  private finalizeLoading() {
    this.isLoading = false;
    plEventBus.$emit(plEvents.uploadingMedias, false);
  }

  public checkIfValid(fileNames: File[]): boolean {
    let isValid: boolean[] = [];
    fileNames.forEach((file) => {
      isValid.push((file.type.indexOf('image/') !== -1 && file.type.indexOf('image/tiff') === -1)
        || (file.type.indexOf('video/') !== -1 && file.type.indexOf('video/avi') === -1));
    });
    return !isValid.some((data) => data === false);
  }

  public async uploadFiles(mediaFiles: File[]): Promise<void> {
    if (mediaFiles?.length === 0) {
      return;
    } else {
      // if selected number media files is greater than remaining allowed to be uploaded
      // then only select the media files so that total does not exceeds the limit
      const remainingAllowed: number = this.maximumMediasLimit - this.validMedias.length;
      if (mediaFiles.length > remainingAllowed) {
        mediaFiles = Array.from(mediaFiles).slice(0, remainingAllowed);
      }
    }

    this.initializeLoading();

    const uploadMediaPromises: Array<Promise<void>> = Array.from(
      mediaFiles
    ).map(async (mediaFile) => {
      const isVideo = mediaFile.type.startsWith('video/');
      if (isVideo) {
        await this.validateUploadVideoRequest(mediaFile);
      } else {
        await this.convertMediaToBase64(mediaFile, false);
      }
    });

    Promise.all(uploadMediaPromises);
  }

  private uploadPhotoVideo(event: Event): void {
    const targetElement: HTMLInputElement = event.target as HTMLInputElement;
    let mediaFiles: File[] = Array.from(targetElement.files!);
    if (this.checkIfValid(mediaFiles)) {
      this.uploadFiles(mediaFiles);
    } else {
      this.finalizeLoading();
      this.$bvModal.show('fileTypeError');
    }
  }

  // Helper Methods...
  private convertMediaToBase64(
    mediaFile: File,
    isVideo: boolean
  ): Promise<void> {
    return new Promise((resolve) => {
      const reader: FileReader = new FileReader();
      let data: any;
      reader.addEventListener(
        'load',
        () => {
          // convert file to base64 string
          data = reader.result;
          if (isVideo) {
            const mediaSource = data.replace(
              'data:video/quicktime;',
              'data:video/mp4;'
            );
            this.thumbnailifyVideo(mediaSource, mediaFile).finally(resolve);
          } else {
            const img: any = new Image();
            img.src = reader.result;
            img.onload = () => {
              const elem = document.createElement(MediaProperty.Canvas);
              elem.width = img.width;
              elem.height = img.height;
              const ctx = elem.getContext('2d');
              ctx?.drawImage(img, 0, 0, elem.width, elem.height);
              const mediaData = elem.toDataURL('image/jpeg', 0.3);
              const mediaSource = mediaData;
              this.thumbnailify(
                mediaFile.name,
                mediaSource,
                mediaData,
                this.thumbnailTargetSize,
                false
              ).finally(resolve);
            };
          }
        },
        false
      );
      if (mediaFile) {
        reader.readAsDataURL(mediaFile);
      }
    });
  }

  private async onPreviewFailure(isVideo: boolean): Promise<void> {
    const mediaData: IProductLogMediaViewModel = Object.assign({});

    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () => {
          mediaData.imageKey = 'Failed-ErrorKey';
          mediaData.thumbnail = '';
          mediaData.title = 'Upload failed: video > 20 s';
          mediaData.isVideo = isVideo;
          mediaData.isSelected = false;
          mediaData.isFailedPreview = true;
          store.commit(
            `${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.appendUploadedMedia.name}`,
            mediaData
          );
        },
        errorMsg: 'Error uploading media: ',
      }
    );
    this.finalizeLoading();
  }

  private async onPreviewSuccess(
    mediaFileName: string,
    mediaSource: string,
    thumbNailIcon: string,
    isVideo: boolean
  ): Promise<void> {
    const mediaData: IProductLogMediaViewModel = Object.assign({});

    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () =>
          store
            .dispatch(
              `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.uploadMedia.name}`,
              this.itemKey
            )
            .then(async (result: IProductLogUploadMediaApiDto) => {
              // Uploading the image with the pre-signed url.
              mediaData.s3Path = result.s3Path;
              mediaData.imageKey = result.imageKey;
              mediaData.thumbnail = thumbNailIcon;
              mediaData.title = mediaFileName;
              mediaData.isVideo = isVideo;
              store.dispatch(
                `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
                {
                  action: async () =>
                    sharedAxiosInstance
                      .put(result.preSignedUrl, mediaSource)
                      .then(async () => {
                        mediaData.isSelected = true;
                        store.commit(
                          `${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.appendUploadedMedia.name}`,
                          mediaData
                        );
                        this.onLibraryMediaItemsSelected();
                        this.finalizeLoading();
                      }),
                  errorMsg: 'Error putting media with presignedUrl: ',
                }
              );
            }),
        errorMsg: 'Error uploading media: ',
      }
    );
  }

  private isValidVideo(
    videoFileName: string,
    videoSource: string,
    videoElement: HTMLVideoElement
  ): string {
    const canvas = document.createElement(MediaProperty.Canvas);
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas
      .getContext('2d')
      ?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  }

  private async snapVideo(
    videoFileName: string,
    videoSource: string,
    videoElement: HTMLVideoElement,
    videoImage: string
  ): Promise<void> {
    await this.thumbnailify(
      videoFileName,
      videoSource,
      videoImage,
      this.thumbnailTargetSize,
      true
    );
  }

  private thumbnailify(
    mediaFileName: string,
    mediaSource: string,
    base64Image: string,
    targetSize: number,
    isVideo: boolean
  ): Promise<void> {
    return new Promise((resolve) => {
      const image = new Image();
      const that = this;
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const canvas = document.createElement(MediaProperty.Canvas);
        const ctx = canvas.getContext('2d');
        canvas.width = canvas.height = targetSize;
        ctx?.drawImage(
          image,
          width > height ? (width - height) / 2 : 0,
          height > width ? (height - width) / 2 : 0,
          width > height ? height : width,
          width > height ? height : width,
          0,
          0,
          targetSize,
          targetSize
        );
        const thumbnailIcon = canvas.toDataURL();
        that
          .onPreviewSuccess(mediaFileName, mediaSource, thumbnailIcon, isVideo)
          .finally(() => {
            resolve();
          });
      };
      image.src = base64Image;
    });
  }

  private thumbnailifyVideo(
    videoSource: string,
    videoFile: File
  ): Promise<void> {
    return new Promise((resolve) => {
      const fileReader: FileReader = new FileReader();
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result ?? ''], {
          type: videoFile.type,
        });
        const url = URL.createObjectURL(blob);
        const videoElement: HTMLVideoElement = document.createElement(MediaProperty.Video);
        const timeupdate = async () => {
          const image = this.isValidVideo(
            videoFile.name,
            videoSource,
            videoElement
          );
          if (image.length > 100000) {
            videoElement.removeEventListener('timeupdate', timeupdate);
            videoElement.pause();
          }
        };
        videoElement.addEventListener('loadeddata', async () => {
          const image = this.isValidVideo(
            videoFile.name,
            videoSource,
            videoElement
          );
          if (image.length > 100000) {
            await this.snapVideo(
              videoFile.name,
              videoSource,
              videoElement,
              image
            );
            videoElement.removeEventListener('timeupdate', timeupdate);
          }
          resolve();
        });
        videoElement.addEventListener('timeupdate', timeupdate);
        videoElement.preload = 'metadata';
        videoElement.src = url;
        // Load video in Safari / IE11
        videoElement.muted = true;
        videoElement.setAttribute(MediaProperty.Playsinline, '');
        videoElement.play();
      };
      fileReader.readAsArrayBuffer(videoFile);
    });
  }

  private validateUploadVideoRequest(videoFile: File): Promise<void> {
    return new Promise((resolve) => {
      const video: HTMLVideoElement = document.createElement(MediaProperty.Video);
      video.preload = 'metadata';
      video.src = URL.createObjectURL(videoFile);
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const promise =
          video.duration > 20
            ? this.onPreviewFailure(true)
            : this.convertMediaToBase64(videoFile, true);
        promise.finally(resolve);
      };
    });
  }
}
