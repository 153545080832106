


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  plEventBus,
  plEvents
} from '@/services/eventBus/product-logging-event-bus';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';
import MediaViewerCarouselCardGroup from '@/components/media/MediaViewerCarouselCardGroup.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'media-viewer-carousel',
  components: {
    MediaViewerCarouselCardGroup
  }
})
export default class MediaViewerCarousel extends Vue {
  // Vue JS Props...
  @Prop({ required: true })
  public medias!: IProductLogMediaViewModel[];

  // Properties...
  public selectedMediaNumber: number = 1;
  public currentGroupIndex: number = 0;
  public currentGroupItemIndex: number = 0;
  public groupSize: number = 6;

  // Helper Methods...
  private get mediaGroups(): IProductLogMediaViewModel[][] {
    const result: IProductLogMediaViewModel[][] = [];
    let startIndex = 0;

    while (startIndex < this.medias.length) {
      result.push(this.medias.slice(startIndex, startIndex + this.groupSize));
      startIndex += this.groupSize;
    }

    return result;
  }

  // Lifecycle Hooks...
  public created(): void {
    plEventBus.$emit(plEvents.showMediaInPreviewer, this.medias[0]);
    plEventBus.$on(
      plEvents.viewerCarouselItemSelected,
      (selectedGroupIndex: number, selectedItemIndex: number) => {
        this.EmitShowMediaInPreviewer(selectedGroupIndex, selectedItemIndex);
      }
    );
  }

  public destroyed() {
    plEventBus.$off(plEvents.viewerCarouselItemSelected);
  }

  // Event Methods...
  private slidePrevious() {
    (this.$refs.mediaViewerCarousel as any).prev();
  }

  private slideNext() {
    (this.$refs.mediaViewerCarousel as any).next();
  }

  // Helper Methods...
  private EmitShowMediaInPreviewer(selectedGroupIndex: number, selectedItemIndex: number) {
    this.currentGroupIndex = selectedGroupIndex;
    this.currentGroupItemIndex = selectedItemIndex;
    const actualMediaIndex = (selectedGroupIndex * this.groupSize) + selectedItemIndex;
    this.selectedMediaNumber = actualMediaIndex + 1;
    plEventBus.$emit(plEvents.showMediaInPreviewer, this.medias[actualMediaIndex]);
  }
}
