



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { plEventBus, plEvents } from '@/services/eventBus/product-logging-event-bus';
import store, { VuexModuleNamespaces } from '@/store';
import { ErrorStore } from '@/store/error/errorStore';
import { IProductLogFlagUpdateRequest, IProductLogs } from '@/view-models/productLog/product-log-view-models';
import { ProductLogStore } from '@/store/productLog/productLogStore';
import Loading from '@/components/common/Loading.vue';
import { AppStore } from '@/store/app/appStore';
Vue.use(BootstrapVue);

@Component({
  name: 'confirm-product-log-clear-flag-modal',
  components: {
    Loading
  }
})
export default class ConfirmProductLogClearFlagModal extends Vue {
  // Vue.JS Props...
  @Prop({ required: true })
  public log!: IProductLogs;

  public isLoading: boolean = false;

  // Event Methods...
  private mounted() {
    this.isLoading = false;
  }

  private cancel(): void {
    this.isLoading = false;
    this.$bvModal.hide(`confirm-product-log-clear-flag-modal[${this.log.key}]`);
  }

  private async clear(): Promise<void> {
    this.isLoading = true;
     // set new action time to be sent as header
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () => {
          const productLogFlagUpdateRequest: IProductLogFlagUpdateRequest = {
            assetKey: this.log.assetKey,
            burnerKey: this.log.burnerKey,
            logKey: this.log.key,
            isFlagged: false
          };
          await
            store.dispatch(`${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.updateProductLogFlag.name}`,
                               productLogFlagUpdateRequest);
          plEventBus.$emit(plEvents.productFlagUpdated);
        },
        errorMsg: 'Error updating product log flag: ',
      }
    );
    this.$bvModal.hide(`confirm-product-log-clear-flag-modal[${this.log.key}]`);
    this.isLoading = false;
  }
}
