


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'media-viewer-carousel-card-item',
})
export default class MediaViewerCarouselCardItem extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public media!: IProductLogMediaViewModel;
  @Prop({ required: true })
  public groupIndex!: number;
  @Prop({ required: true })
  public index!: number;
  @Prop()
  public isSelected!: boolean;

  // Event Methods...
  private selectMedia(): void {
    this.media.isSelected = true;
    plEventBus.$emit(
      plEvents.viewerCarouselItemSelected,
      this.groupIndex,
      this.index
    );
  }
}
