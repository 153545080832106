import { ActionContext, ActionTree, MutationTree, GetterTree } from 'vuex';
import { IRootState } from '..';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';

export interface IProductLogMediaLibraryStoreState {
  medias: IProductLogMediaViewModel[];
}

export interface IProductLogMediaLibraryActions
  extends ActionTree<IProductLogMediaLibraryStoreState, IRootState> {}

export interface IProductLogMediaLibraryGetters
  extends GetterTree<IProductLogMediaLibraryStoreState, IRootState> {}

export interface IProductLogMediaLibraryMutations
  extends MutationTree<IProductLogMediaLibraryStoreState> {
  appendUploadedMedia(
    state: IProductLogMediaLibraryStoreState,
    media: IProductLogMediaViewModel
  ): void;
  clearMedias(state: IProductLogMediaLibraryStoreState): void;
  deleteUploadedMedia(
    state: IProductLogMediaLibraryStoreState,
    media: IProductLogMediaViewModel
  ): void;
  setMedias(
    state: IProductLogMediaLibraryStoreState,
    medias: IProductLogMediaViewModel[]
  ): void;
  updateSelectedMedia(
    state: IProductLogMediaLibraryStoreState,
    media: IProductLogMediaViewModel
  ): void;
  resetMediaSelections(state: IProductLogMediaLibraryStoreState): void;
  setSelectedMedias(
    state: IProductLogMediaLibraryStoreState,
    selectedMedias: IProductLogMediaViewModel[]
  ): void;
  updateTitleForSelectedMedias(
    state: IProductLogMediaLibraryStoreState,
    item: IUpdateMediaTitle
  ): void;
  removeSelectedMedias(
    state: IProductLogMediaLibraryStoreState,
    mediaKey: string
  ): void;
}

export interface IUpdateMediaTitle {
  mediaKey: string;
  title: string;
}

export type IProductLogMediaLibraryContext = ActionContext<
  IProductLogMediaLibraryStoreState,
  IRootState
>;

export const ProductLogMediaLibraryStore = {
  namespaced: true as true,
  state: ({
    medias: [],
  } as unknown) as IProductLogMediaLibraryStoreState,
  getters: {} as IProductLogMediaLibraryGetters,
  mutations: {
    appendUploadedMedia(
      state: IProductLogMediaLibraryStoreState,
      media: IProductLogMediaViewModel
    ): void {
      if (media) {
        state.medias.push(media);
      }
    },
    clearMedias(state: IProductLogMediaLibraryStoreState) {
      state.medias = [];
    },
    deleteUploadedMedia(
      state: IProductLogMediaLibraryStoreState,
      media: IProductLogMediaViewModel
    ): void {
      if (media) {
        const matchedMediaIndex = state.medias.findIndex(
          (m) => m.imageKey === media.imageKey
        );
        state.medias.splice(matchedMediaIndex, 1);
      }
    },
    setMedias(
      state: IProductLogMediaLibraryStoreState,
      medias: IProductLogMediaViewModel[]
    ): void {
      state.medias = [];
      if (medias) {
        medias.forEach((media) => {
          media.isSelected = true;
          state.medias.push(media);
        });
      } else {
        state.medias = [];
      }
    },
    updateSelectedMedia(
      state: IProductLogMediaLibraryStoreState,
      media: IProductLogMediaViewModel
    ): void {
      const newMedia: IProductLogMediaViewModel = Object.assign({}, media);
      const index: number = state.medias.findIndex((currentMedia) => currentMedia.imageKey === newMedia.imageKey);
      if (index < 0) {
        return;
      }
      state.medias.splice(index, 1, newMedia);
    },
    resetMediaSelections(state: IProductLogMediaLibraryStoreState): void {
      // Resetting all the images.
      state.medias.forEach((media) => {
        media.isSelected = media.isPrevSelected = media.isRemoved = false;
      });
    },
    setSelectedMedias(
      state: IProductLogMediaLibraryStoreState,
      selectedMedias: IProductLogMediaViewModel[]
    ) {
      state.medias.forEach((media) => {
        const matchedMedia: IProductLogMediaViewModel | undefined =
          selectedMedias.find((selectedMedia) => selectedMedia.imageKey === media.imageKey);
        if (matchedMedia === undefined) {
          media.isSelected = false;
        } else {
          media.isSelected = true;
        }
      });
    },
    updateTitleForSelectedMedias(
      state: IProductLogMediaLibraryStoreState,
      item: IUpdateMediaTitle
    ): void {
      const index = state.medias.findIndex(
        (media) => media.imageKey === item.mediaKey
      );
      const matchedMedia: IProductLogMediaViewModel = state.medias[index];
      if (matchedMedia.isSelected) {
        matchedMedia.title = item.title;
      }
    },
    removeSelectedMedias(
      state: IProductLogMediaLibraryStoreState,
      mediaKey: string
    ): void {
      const index = state.medias.findIndex(
        (item) => item.imageKey === mediaKey
      );
      state.medias.splice(index, 1);
    },
  } as IProductLogMediaLibraryMutations,
  actions: {} as IProductLogMediaLibraryActions,
};
