import { AxiosInstance } from 'axios';
import BaseService from '@/services/common/base-service';
import {
  IEntityLogUploadMediaApiDto,
  IProductLogUploadRequest,
  IProductLogDownloadRequest,
  IProductLogDeleteRequest
} from '@/store/productLog/productLogStore';
import {
  IAddEntityLogRequest,
  IEntityLogFlagUpdateRequest,
  IEntityLogResponse,
  IEntityLogs,
  IProductLogRequest } from '@/view-models/productLog/product-log-view-models';

export default class EntityLogService extends BaseService {

  constructor(entityLogApiBaseUrl: string, axios: AxiosInstance) {
    super(`${entityLogApiBaseUrl}/aov`, axios);
  }

  /**
   * Uploads image and it returns image.
   * @returns {Promise<IEntityLogUploadMediaApiDto>}
   * @param base64string: string
   */
  public async uploadMedia(request: IProductLogUploadRequest): Promise<IEntityLogUploadMediaApiDto> {
    const url: string = `Logs/${request.assetKey}/entity/${request.key}/upload-media`;
    return this.httpClient.get<IEntityLogUploadMediaApiDto>(url);
  }

  /**
   * Downloads image as base64string.
   * @returns base64string string
   */
  public async downloadMedia(request: IProductLogDownloadRequest): Promise<string> {
    const url: string = `Logs/${request.assetKey}/entity/${request.key}/media/${request.mediaKey}/download`;
    return this.httpClient.get<string>(url);
  }

  public async addLog(data: IAddEntityLogRequest): Promise<IAddEntityLogRequest> {
    return this.httpClient.post<IAddEntityLogRequest>('Logs', data);
  }

  public async updateLog(data: IAddEntityLogRequest): Promise<IAddEntityLogRequest> {
    return this.httpClient.put<IAddEntityLogRequest>('Logs', data);
  }

  public async deleteLog(data: IProductLogDeleteRequest): Promise<void> {
    return this.httpClient.delete(`Logs/${data.assetKey}/entity/${data.entityKey}/log/${data.logKey}`);
  }
  /**
   * Gets asset logs
   */
  public async getLogs(request: IProductLogRequest): Promise<IEntityLogResponse> {
    let url: string = this.getUrl(
      `Logs/${request.parentKey}/entity/${request.key}`
    );
    if (request.currentPage !== null && request.currentPage !== undefined) {
      url += '?CurrentPage=' + request.currentPage;
    }
    if (request.perPage !== null && request.currentPage !== undefined) {
      if (url.indexOf('CurrentPage') > -1) {
        url += '&PerPage=' + request.perPage;
      } else {
        url += '?PerPage=' + request.perPage;
      }
    }
    if (request.searchTerm !== null && request.searchTerm !== undefined) {
      url += url.indexOf('?') > -1 ? '&' : '?';
      url += 'LogFilterType=' + request.searchTerm;
    }
    return this.httpClient.get<IEntityLogResponse>(url);
  }

  /**
   * Update burner log flag using a log key and a flag value
   * @returns {Promise<IEntityLogs>}
   */
  public async updateProductLogFlag(request: IEntityLogFlagUpdateRequest): Promise<IEntityLogs> {
    const url: string = this.getUrl(
      `Logs/${request.assetKey}/entity/${request.entityKey}/update-flag/${request.logKey}/${request.isFlagged}`
    );
    return this.httpClient.put<IEntityLogs>(url);
  }
}
