import { render, staticRenderFns } from "./ConfirmDeleteModal.vue?vue&type=template&id=ed7936f8&scoped=true&"
import script from "./ConfirmDeleteModal.vue?vue&type=script&lang=ts&"
export * from "./ConfirmDeleteModal.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./ConfirmDeleteModal.vue?vue&type=style&index=0&id=ed7936f8&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "ed7936f8",
  null
  ,true
)

export default component.exports