


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'fullscreen-image-viewer-modal',
})
export default class FullscreenImageViewerModal extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public mediaKey!: string;
  @Prop({ required: true })
  public mediaSource!: string;

  // Event Methods...
  private close(): void {
    this.$bvModal.hide('fullscreen-image-viewer-modal');
  }
}
