import { IRootState } from '..';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IMetadataDetails, IUser } from '@/view-models/user/user-view-models';
import { UserService } from '@/services/user/user-service';
import ConfigFactory from '@/services/config/config';
import Axios from 'axios';
import authInterceptor from '@/services/common/service-interceptor';

export interface IUserStoreState {
  currentUser: IUser;
  userInfoService: UserService;
  customerAssetInfo: IMetadataDetails;
}

export interface IUserStoreActions
  extends ActionTree<IUserStoreState, IRootState> {
  getCurrentUser(context: IUserContext): Promise<void>;
}

export interface IUserStoreGetters
  extends GetterTree<IUserStoreState, IRootState> {
  currentUser(state: IUserStoreState): IUser;
}

export interface IUserStoreMutations
  extends MutationTree<IUserStoreState> {
  updateCurrentUser(state: IUserStoreState, user: IUser): void;
  updateCustomerAssetInfo(state: IUserStoreState, details: IMetadataDetails): void;
}

const initUserService = async () => {
  const conf = await ConfigFactory.GetConfig();
  const axiosInstance = Axios.create();
  axiosInstance.interceptors.request.use(authInterceptor);
  return new UserService(
      conf.get('umApiUrl'),
      axiosInstance
  );
};

export type IUserContext = ActionContext<IUserStoreState, IRootState>;

export const UserStore = {
  namespaced: true as true,
  state: {
  } as IUserStoreState,
  actions: {
    async getCurrentUser(context: IUserContext) {
      const userService = await initUserService();
      await userService.getUser().then((response: IUser) => {
        context.commit(UserStore.mutations.updateCurrentUser.name, response);
      });
    }
  },
  getters: {
    currentUser(state: IUserStoreState) {
      return state.currentUser;
    }
  },
  mutations: {
    updateCurrentUser(
      state: IUserStoreState,
      user: IUser) {
      state.currentUser = user;
    },
    updateCustomerAssetInfo(state: IUserStoreState, details: IMetadataDetails) {
      state.customerAssetInfo = details;
    }
  }
};
