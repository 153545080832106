import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import LoggerService from '@/services/logger/logger-service';
import { ILog } from '@/view-models/common/log-model';

export const getBrowserDetails = () => {
  const browserData = navigator.userAgent;
  let browserName, verOffset, browserVersion;
  if ((verOffset = browserData.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    browserVersion = browserData.substring(verOffset + 6);
  } else if ((verOffset = browserData.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    browserVersion = browserData.substring(verOffset + 5);
  } else if ((verOffset = browserData.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    browserVersion = browserData.substring(verOffset + 7);
  } else if ((verOffset = browserData.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    browserVersion = browserData.substring(verOffset + 7);
  } else if ((verOffset = browserData.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    browserVersion = browserData.substring(verOffset + 8);
  }
  browserVersion = parseInt('' + browserVersion, 10);
  return {
    name: browserName,
    version: browserVersion.toString(),
  };
};

export const generateLoggerPayload = (msg: string, feature: string, additionalData: { [key: string]: string }): ILog => {
  const browserData = getBrowserDetails();
  return {
    message: msg,
    applicationName: 'Entity_Logs_FE',
    browserName: browserData.name ? browserData.name : '',
    browserVersion: browserData.version ? browserData.version : '',
    frontendFeature: feature,
    logLevel: 'Information',
    additionalProperty: additionalData,
    stackTrace: 'N/A',
  };
};

export const generateErrorLoggerPayload = (errorInfo: Error, assetKey: string): ILog => {
  const browserData = getBrowserDetails();
  return {
    message: errorInfo.message,
    applicationName: 'Entity_Logs_FE',
    browserName: browserData.name ? browserData.name : '',
    browserVersion: browserData.version ? browserData.version : '',
    frontendFeature: null,
    logLevel: 'Error',
    additionalProperty: {
      assetKey: assetKey
    },
    stackTrace: errorInfo.stack ? errorInfo.stack : 'N/A',
  };
};

export const initLoggerService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
};
