import BaseService from '@/services/common/base-service';
import { ILog } from '@/view-models/common/log-model';
import { AxiosInstance } from 'axios';

export default class LoggerService extends BaseService {

	constructor(logApiUrl: string, axios: AxiosInstance) {
		super(`${logApiUrl}`, axios);
	}

	public async addLogs(log: ILog) {
    await this.httpClient.post('aov/Loggers/ui-log', log);
  }
}
