


















































































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BootstrapVue, BDropdown } from 'bootstrap-vue';
import VueMarkdown from 'vue-markdown';
import {
  IProductLogs,
  ChildComments,
  CommentActionType,
  CommentType,
  FilterType,
  IProductLogFlagUpdateRequest,
} from '@/view-models/productLog/product-log-view-models';
import CommentEditor from '@/components/productLogs/CommentEditor.vue';
import ImageThumbnail from '@/components/productLogs/ImageThumbnail.vue';
import Loading from '@/components/common/Loading.vue';
import { UserStore } from '@/store/user/userStore';
import { ProductLogStore } from '@/store/productLog/productLogStore';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import store, { VuexModuleNamespaces } from '@/store';
import { ErrorStore } from '@/store/error/errorStore';
import { LogsMode, AppStore } from '@/store/app/appStore';
import ConfirmProductLogClearFlagModal from '@/components/modals/ConfirmProductLogClearFlagModal.vue';
import { TransactionName } from '@/enums/transaction-name';
import BaseComponent from '../common/BaseComponent';
import { Permission } from '@/view-models/user/user-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'ProductLog',
  components: {
    CommentEditor,
    ImageThumbnail,
    Loading,
    VueMarkdown,
    ConfirmProductLogClearFlagModal
  },
})
export default class ProductLog extends BaseComponent {
  @Prop({ required: true })
  public log!: IProductLogs;
  @Prop({ required: true })
  public childLogs!: ChildComments;
  @Prop({ required: true })
  public showReplyEditor!: boolean;
  @Prop({ required: true })
  public showReplyEditorArea!: () => void;
  @Prop({ required: true })
  public filterType!: string;
  @Prop({ required: true })
  public isLoadingMoreLogs!: boolean;
  @Prop({ required: true })
  public currentReplyThread: string = '';
  @Prop({ required: true })
  public selectedProductKey!: string;
  public logsMode = LogsMode;
  public CommentType = CommentType;
  private FilterType = FilterType;

  // Properties...
  private isCommentActionsOpen: boolean = false;
  private isReplyCommentActionsOpen: boolean = false;
  private CommentActionType = CommentActionType;
  private editLog: IProductLogs | null = null;
  private isEditMode: boolean = false;
  private isCommentingModeActive = false;

  public created() {
    plEventBus.$on(plEvents.commentEditorClosed, () => {
      this.isEditMode = false;
    });
    plEventBus.$on(plEvents.activeCommentingModeChanged, async (value: boolean) => {
      this.isCommentingModeActive = value;
    });
  }

  // getters
  get productKey(): string {
    const logsMode: LogsMode | null =
      store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
    if (logsMode && logsMode === LogsMode.BURNER) {
      return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.productKey.name}`];
    } else if (logsMode && (logsMode === LogsMode.ASSET || logsMode === LogsMode.TOWER)) {
      return this.log.burnerKey ??
        this.log.taskKey ??
        store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.productKey.name}`];
    } else {
      return '';
    }
  }

  // Private Methods...
  private showBurnerLogHeader() {
    const logsMode: LogsMode | null =
      store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
    if (logsMode && logsMode === LogsMode.BURNER) {
      return false;
    } else if (logsMode && logsMode === LogsMode.ASSET) {
      if ('burnerKey' in this.log) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private showTaskLogHeader() {
    const logsMode: LogsMode | null =
      store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
    if (logsMode && logsMode === LogsMode.TASK) {
      return false;
    } else if (logsMode && (logsMode === LogsMode.ASSET || logsMode === LogsMode.TOWER)) {
      if ('taskKey' in this.log) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private getBurnerName(): string {
    if ('burnerName' in this.log &&
        this.log.burnerName !== null &&
        this.log.burnerName !== undefined ) {
      return this.log.burnerName;
    } else {
      return '';
    }
  }

  private getTaskName(): string {
    if ('taskName' in this.log &&
        this.log.taskName !== null &&
        this.log.taskName !== undefined ) {
      return this.log.taskName;
    } else {
      return '';
    }
  }

  private userHasAccessToCommentActions(log: IProductLogs): boolean {
    const currentUser = UserStore.state.currentUser;
    return currentUser.isSuperUser || (currentUser.isAdminCompanyUser && currentUser.permissions?.includes(Permission.DeleteLogs)) || log.userKey === currentUser.key;
  }

  private userHasAccessToDeleteComment(log: IProductLogs): boolean {
    const currentUser = UserStore.state.currentUser;
    return currentUser.isSuperUser || (currentUser.isAdminCompanyUser && currentUser.permissions?.includes(Permission.DeleteLogs)) || log.userKey === currentUser.key;
  }

  private userHasAccessToEditComment(log: IProductLogs): boolean {
    const currentUser = UserStore.state.currentUser;
    return currentUser.isSuperUser || log.userKey === currentUser.key;
  }

  // Event Methods...
  private deleteComment(log: IProductLogs) {
    plEventBus.$emit(plEvents.tryDeleteComment, log);
  }

  private editComment(log: IProductLogs) {
    this.editLog = log;
    plEventBus.$emit(plEvents.showTransparentOverlay, true);
    this.isEditMode = true;
  }

  private toggleCommentActionsDropdown() {
    const commentActionsDropDown = this.$refs.commentActionsDropdown as BDropdown;
    if (this.isCommentActionsOpen) {
      commentActionsDropDown.hide();
      } else {
      commentActionsDropDown.show();
    }
    this.isCommentActionsOpen = !this.isCommentActionsOpen;
  }

  private toggleReplyCommentActionsDropdown(replyLog: IProductLogs) {
    const replyCommentActionsDropDowns = this.$refs[`replyCommentActionsDropDown[${replyLog.key}]`] as BDropdown[];
    if (this.isReplyCommentActionsOpen) {
      replyCommentActionsDropDowns[0].hide();
    } else {
      replyCommentActionsDropDowns[0].show();
    }
    this.isReplyCommentActionsOpen = !this.isReplyCommentActionsOpen;
  }

  private async toggleCommentFlag(log: IProductLogs): Promise<void> {
    if (log.isFlagged) {
      this.$bvModal.show(`confirm-product-log-clear-flag-modal[${log.key}]`);
    } else {
      await this.updateProductLogCommentFlag(log.key, true);
    }
  }

  // Helper Methods...
  private async updateProductLogCommentFlag(key: string, flag: boolean): Promise<void> {
    plEventBus.$emit(plEvents.tryUpdateProductFlag);
    // set new action time to be sent as header
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);
    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () => {
          const productLogFlagUpdateRequest: IProductLogFlagUpdateRequest = {
            assetKey: this.log.assetKey,
            burnerKey: this.log.burnerKey,
            logKey: key,
            isFlagged: flag
          };
          await
            store.dispatch(`${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.updateProductLogFlag.name}`,
                               productLogFlagUpdateRequest);
          plEventBus.$emit(plEvents.productFlagUpdated);
        },
        errorMsg: 'Error updating product log flag: ',
      }
    );
  }

  private clearLogsEditor(value: boolean) {
    if (value) {
      this.tryCloseCommentEditor();
    }
  }

  private tryCloseCommentEditor() {
    if (this.isCommentingModeActive) {
      this.$bvModal.show('confirmDiscardCommentModal');
    } else {
      plEventBus.$emit(plEvents.commentEditorClosed);
    }
  }

  public mounted() {
    this.stopTransaction(TransactionName.InitialLoad);
  }

}
