












import { Vue, Prop, Component, Emit } from 'vue-property-decorator';

@Component({
  name: 'icon',
})
export default class Icon extends Vue {
  @Prop({ required: true })
  public name!: string;
  @Prop({ default: '0 0 24 24' })
  public viewBox?: string;
  @Prop({ default: '24px' })
  public width?: string;
  @Prop({ default: '24px' })
  public height?: string;
  @Emit('click')
  public onClick() { return 'clicked'; }
}
