








































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import {
  plEvents,
  plEventBus,
} from '@/services/eventBus/product-logging-event-bus';

Vue.use(BootstrapVue);

@Component({
  name: 'confirm-discard-comment-modal',
})
export default class ConfirmDiscardCommentModal extends Vue {
  public confirmDiscardComment() {
    plEventBus.$emit(plEvents.commentEditorClosed);
    this.$bvModal.hide('confirmDiscardCommentModal');
  }

  private cancel(): void {
    this.$bvModal.hide('confirmDiscardCommentModal');
  }
}
