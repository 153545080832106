import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '../../store';
import { IErrorBusMessage, ErrorBusMessage, IErrorMessageConfig } from '@/view-models/error/error-model';
import { generateErrorLoggerPayload, initLoggerService } from '@/utils/logs-helper';
import LoggerService from '@/services/logger/logger-service';

const EVENT_NAME = 'GLOBAL_ERROR';
const APP_TAG = 'PRODUCT_LOGGING';

export interface IErrorStoreState {
    error: any; // Error message
    handleError: boolean; // Handle error within child app
    routeHomeAfterError: boolean; // Force user to return to home screen
    showModal: boolean; // Show child app error modal
    eventBus: any; // Parent app error event bus
}

export interface IErrorStoreGetters extends GetterTree<IErrorStoreState, IRootState> {
  getError(state: IErrorStoreState): string;
}

export interface IErrorStoreMutations extends MutationTree<IErrorStoreState> {
  clearError(state: IErrorStoreState): void;
}

export interface IErrorStoreActions extends ActionTree<IErrorStoreState, IRootState> {
  setError(context: IErrorContext, payload: {error: any, config?: IErrorMessageConfig}): Promise<void>;
  parseError(context: IErrorContext, payload: any): string;
  parseClientError(context: IErrorContext, payload: any): Promise<string>;
  parseServiceError(context: IErrorContext, payload: any): Promise<string>;
  tryExecute<T>(context: IErrorContext, action: any): Promise<void>;
  sendErrorLog(context: IErrorContext, payload: Error): Promise<void>;
}

export type IErrorContext = ActionContext<IErrorStoreState, IRootState>;

export const ErrorStore = {
    namespaced: true,
    state: {
        error: null,
        handleError: false,
        routeHomeAfterError: false,
        showModal: false
    } as IErrorStoreState,
    getters: {
        getError(state: IErrorStoreState) {
            return state.error;
        },
        getRouteHomeAfterError(state: IErrorStoreState) {
            return state.routeHomeAfterError;
        }
    } as IErrorStoreGetters,
    mutations: {
      clearError: (state: IErrorStoreState) => {
          state.error = null;
          state.handleError = false;
          state.routeHomeAfterError = false;
          state.showModal = false;
        }
    } as IErrorStoreMutations,
    actions: {
      async setError(context: IErrorContext, payload: {error: any, errorString: string,
        handleError: boolean, routeHomeAfterError: boolean}): Promise<void> {
          // Checking if payload has an error instance
          if (payload.error) {
              // Check if error has a response
              if (payload.error.response) {
                  const statusCode = payload.error.response.status;
                  if (statusCode >= 400 && statusCode < 500) {
                    context.state.error = await context.dispatch('parseClientError', payload);
                  } else if (statusCode >= 500) {
                    context.state.error = await context.dispatch('parseServiceError', payload);
                  } else {
                    context.state.error = payload.errorString + '.\nUnknown Error: ' + statusCode;
                  }
              } else if (payload.error.message) {
                context.state.error = payload.errorString + payload.error.message;
              } else if (payload.error.error_description) {
                context.state.error = payload.errorString + '.\n' + payload.error.error_description;
              } else { // This is handling non http error codes
                context.state.error = payload.errorString;
              }
          } else { // Setting an error with error string passed if error instance is not present
              context.state.error = payload.errorString;
          }
          context.state.handleError = payload.handleError;
          context.state.routeHomeAfterError = payload.routeHomeAfterError;
          emitError();
      },
      async tryExecute<T>(context: IErrorContext, payload: any): Promise<void> {
          // action: () => Promise<void>, errorMsg: string): Promise<void>
          try {
              if (payload.action) {
                  await payload.action();
              }
          } catch (err) {
              const errorString = `${payload?.errorMsg}\n`;
              await context.dispatch('sendErrorLog', err);
              await context.dispatch('setError', {
                  error: err,
                  errorString,
                  handleError: true,
                  routeHomeAfterError: payload?.routeHomeAfterError ?? false
              });
          }
      },
      async parseClientError(context: IErrorContext, payload: any): Promise<string> {
          switch (payload.error.response.status) {
              case 400:
                  return payload.errorString + 'Bad request.';
              case 404: {
                  return payload.errorString + 'Service not found.';
              }
              case 401:
              case 403: {
                  if (payload.error.message) {
                      return payload.error.message + '.\n' + 'Access denied.';
                  } else {
                      return payload.errorString + 'Access denied.';
                  }
              }
              default: {
                return context.dispatch('parseError', payload);
              }
          }
      },
      async parseServiceError(context: IErrorContext, payload: any): Promise<string> {
          switch (payload.error.response.status) {
                  case 500: {
                      if (payload.error.message) {
                          return payload.errorString + payload.error.message + '.\n' + 'Service error.';
                      } else {
                          return payload.errorString + 'Service error.';
                      }
                  }
                  case 501:
                  case 502:
                  case 503:
                  case 504: {
                      if (payload.error.message) {
                        return payload.errorString + payload.error.message + '.\n' + 'Service unavailable.';
                      } else {
                          return payload.errorString + 'Service unavailable.';
                      }
                  }
                  default: {
                    return context.dispatch('parseError', payload);
                 }
          }
      },
      parseError(context: IErrorContext, payload: any): string {
          if (payload.error.message) {
              return payload.error.message + '.';
          } else if (payload.error.error_description) {
              return payload.error.error_description + '.';
          } else {
              return payload.errorString;
          }
      },
      async sendErrorLog(context: IErrorContext, error: Error): Promise<void> {
        if (error && error.message) {
          const assetDetails = store.state.app.assetKey;
          const loggerService: LoggerService = await initLoggerService();
          const payload = generateErrorLoggerPayload(error, assetDetails ?? '');
          loggerService.addLogs(payload);
        }
      }
    } as IErrorStoreActions
};

const emitError = () => {
  // If inside the parent app, then send the error to the parent app.  Else, handle the error inside the child app.
  if ((window as any).eftEventBus != null) {
    const config: IErrorMessageConfig = {
      logSilently: false,
      showMessage: true
    };
    const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, ErrorStore.state.error, config);
    (window as any).eftEventBus.$emit(EVENT_NAME, emitEvent);
  } else {
    // Show internal error modal if handle error is true
    if (ErrorStore.state.handleError) {
      ErrorStore.state.showModal = true;
    }
  }
};
