






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import MediaViewerModal from '@/components/modals/MediaViewerModal.vue';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'image-thumbnail',
  components: {
    MediaViewerModal,
  },
})
export default class ImageThumbnail extends Vue {
  @Prop({ required: true })
  public productKey!: string;
  @Prop({ required: true })
  public logKey!: string;
  @Prop({ required: true })
  public associatedMedias!: IProductLogMediaViewModel[];

  // Event Methods...
  private openMediaViewer() {
    this.$bvModal.show(`media-viewer-modal[${this.logKey}]`);
  }
}
