





















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import ProductLog from '@/components/productLogs/ProductLog.vue';
import Loading from '@/components/common/Loading.vue';
import store, { VuexModuleNamespaces } from '@/store';
import { AppStore, LogsMode } from '@/store/app/appStore';
import { ErrorStore } from '@/store/error/errorStore';
import { ProductLogStore } from '@/store/productLog/productLogStore';
import {
  IProductLogs,
  IProductLogResponse,
  CommentType,
  ChildComments,
  FilterType,
  FilterSearchTerm,
  ConfirmDiscardContext,
} from '@/view-models/productLog/product-log-view-models';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import VueMarkdown from 'vue-markdown';
import _ from 'lodash';
import ConfirmDeleteModal from '@/components/modals/ConfirmDeleteModal.vue';
import { TransactionName } from '@/enums/transaction-name';
import BaseComponent from '../common/BaseComponent';

Vue.use(BootstrapVue);

@Component({
  name: 'ProductLogs',
  components: {
    ProductLog,
    Loading,
    VueMarkdown,
    ConfirmDeleteModal,
  },
})
export default class ProductLogs extends BaseComponent {
  @Prop({ required: true })
  public commentInProgress!: boolean;
  @Prop({ required: true })
  public filterType!: string;

  public isRetrievingLogs: boolean = false;
  public isLoading: boolean = false;
  public isLoadingMoreLogs: boolean = false;
  public flaggedLogs: IProductLogs[] = [];
  public unflaggedLogs: IProductLogs[] = [];
  public rawProductLogs: IProductLogs[] = [];
  public rawProductLogKeys: string[] = [];
  public childLogs: ChildComments = {};
  public showReplyEditor: boolean = false;
  public currentReplyThread: string = '';
  public logsContainer: HTMLElement | null | undefined = null;
  public currentLogPage: number = 0;
  public logsPerPage: number = 20;
  public totalNumberOfProductLogs: number = 0;
  public lastRetrievedLog: number = 0;
  public selectedProductKey: string = '';
  public logType = LogsMode;
  private FilterType = FilterType;
  private ConfirmDiscardContext = ConfirmDiscardContext;
  private logKeyToBeDeleted: string | null = null;
  private entityLogKeyToBeDeleted: string | null = null;
  private logToBeDeletedIsParentComment: boolean = false;
  private logToBeDeletedIsParentCommentAndHasReplies: boolean = false;
  private deleteModalClass: [string] = ['deleteModalClass'];

  get logsMode() {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.logsMode.name}`];
  }

  public get parentAppName() {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.parentApp.name}`];
  }

  public get isLayoutViewer() {
    return this.parentAppName === 'asset-diagram-viewer' || this.logsMode === LogsMode.BURNER;
  }

  get getFlaggedDates() {
    return _.uniq(
      this.tasksFilter(this.flaggedLogs).map((log) =>
        this.$options.filters?.formatDateLongWithoutTime(log.lastModifiedDate)
      )
    );
  }

  get getUnflaggedDates() {
    return _.uniq(
      this.tasksFilter(this.unflaggedLogs).map((log) =>
        this.$options.filters?.formatDateLongWithoutTime(log.lastModifiedDate)
      )
    );
  }

  get filteredLogsCount() {
    return (
      this.tasksFilter(this.flaggedLogs).length +
      this.tasksFilter(this.unflaggedLogs).length
    );
  }

  get productKey():string {
    return store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.productKey.name}`];
  }

  public get ihdFlag() {
    return false;
  }

  public created() {
    plEventBus.$on(plEvents.commentEditorClosed, () => {
      this.showReplyEditor = false;
    });

    plEventBus.$on(plEvents.commentAddedOrDeleted, async () => {
      // Need to reload products on this event
       if ((this.filterType.toUpperCase() === FilterType.ALL.toUpperCase() && this.isLayoutViewer)
          || (this.filterType.toUpperCase() === FilterType.FLAGGED.toUpperCase() && !this.isLayoutViewer)) {
         await this.initializeSelection(this.selectedProductKey, true);
       }
    });

    plEventBus.$on(plEvents.tryUpdateProductFlag, () => {
      this.isLoading = true;
    });

    plEventBus.$on(plEvents.tryAddorEditComment, () => {
      this.isLoading = true;
    });

    plEventBus.$on(plEvents.productFlagUpdated, async () => {
      // Need to reload products on this event
      await this.initializeSelection(this.selectedProductKey, true);
    });

    plEventBus.$on(plEvents.tryDeleteComment, (log: IProductLogs) => {
      this.logKeyToBeDeleted = log.key;
      if (log.burnerKey !== null && log.burnerKey !== undefined && log.burnerKey !== '') {
        this.entityLogKeyToBeDeleted = log.burnerKey;
      } else if (log.taskKey !== null && log.taskKey !== undefined && log.taskKey !== '') {
        this.entityLogKeyToBeDeleted = log.taskKey;
      } else {
        this.entityLogKeyToBeDeleted = log.assetKey;
      }
      this.logToBeDeletedIsParentComment = log.parentKey ? false : true;

      if (this.logToBeDeletedIsParentComment) {
        let hasReplies: boolean = false;
        this.childLogs[log.key].forEach((childLog) => {
          if (childLog.logType.toUpperCase() === CommentType.REPLY.toUpperCase()) {
            hasReplies = true;
          }
        });
        this.logToBeDeletedIsParentCommentAndHasReplies = hasReplies;
      } else {
        this.logToBeDeletedIsParentCommentAndHasReplies = false;
      }

      this.$bvModal.show('confirm-delete-modal');
    });

    plEventBus.$on(plEvents.setProductKey, async (key: string) => {
      plEventBus.$emit(plEvents.commentEditorClosed);
      await this.initializeSelection(key);
    });

    plEventBus.$on(plEvents.contentScrollable, (scrollable: boolean) => {
      const productLogs = this.$refs.productLogsElement as HTMLElement;
      if (scrollable) {
        productLogs.style.overflowY = 'auto';
      } else {
        productLogs.style.overflowY = 'hidden';
      }
    });
  }

  public async mounted() {
    this.logsContainer = this.$refs.productLogsElement as HTMLElement;
    await this.initializeSelection(this.productKey);
  }

  public async initializeSelection(selectedProductKey: string, isRefreshRequired?: boolean) {
    // Set key
    if (!this.selectedProductKey || this.selectedProductKey !== selectedProductKey || isRefreshRequired) {
    this.selectedProductKey = selectedProductKey;
    // Clear organizational data structures
    this.resetValues();
    // Get initial logs
    await this.refreshProductLogs(true);
    }
  }

  private async refreshProductLogs(
    isInitialLoad: boolean,
    keepLoading?: boolean
  ) {
    if (this.isRetrievingLogs && !isInitialLoad) {
      // Was unsuccessful in loading last time, don't get stuck in a loop
      this.isLoading = false;
      this.isLoadingMoreLogs = false;
      return;
    }
    // Determine where to display loading spinner
    if (isInitialLoad) {
      this.isLoading = true;
    } else {
      this.isLoadingMoreLogs = true;
    }
    // set new action time to be sent as header
    store.commit(`${VuexModuleNamespaces.app}/${AppStore.mutations.updateActionTime.name}`);

    // Retrieve product logs
    await store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: this.retrieveLogs,
        errorMsg: 'Error retrieving product logs.',
        routeHomeAfterError: false,
      }
    );

    // Organize and sort raw product logs
    this.rawProductLogs.forEach((retrievedLog) => {
      if (this.rawProductLogKeys.indexOf(retrievedLog.key) > -1) {
        return;
      } else {
        this.rawProductLogKeys.push(retrievedLog.key);
      }
      // Sort parent and child logs
      if (
        retrievedLog.parentKey !== undefined &&
        retrievedLog.parentKey !== null &&
        retrievedLog.parentKey !== ''
      ) {
        // Add to existing child comments for that parent key.
        if (retrievedLog.parentKey in this.childLogs) {
          this.childLogs[retrievedLog.parentKey].push(retrievedLog);
        } else {
          this.childLogs[retrievedLog.parentKey] = [retrievedLog];
        }
      } else {
        const logsList =
          retrievedLog &&
          retrievedLog.isFlagged &&
          retrievedLog.logType.toUpperCase() === CommentType.LOG
            ? this.flaggedLogs
            : this.unflaggedLogs;
        logsList.push(retrievedLog);
        // Check if child logs are being tracked for this comment.
        if (!(retrievedLog.key in this.childLogs)) {
          this.childLogs[retrievedLog.key] = [];
        }
      }
    });

    // Sort child logs by modified date
    [...this.flaggedLogs, ...this.unflaggedLogs].forEach((parentLog) =>
      this.sortLogs(this.childLogs[parentLog.key], false)
    );
    // Sort and group parent logs by modified date
    this.sortLogs(this.flaggedLogs, true);
    this.sortLogs(this.unflaggedLogs, true);

    // Determine where to destroy loading spinner
    if (!keepLoading || this.isRetrievingLogs) {
      if (isInitialLoad) {
        this.isLoading = false;
      } else {
        this.isLoadingMoreLogs = false;
      }
    }
    this.stopTransaction(TransactionName.LogFilter);
  }

  private async retrieveLogs() {
    this.isRetrievingLogs = true;
    // Save product key for later comparison.  This is to account for
    // external changes to this.selectedProductKey (i.e., the user quickly
    // switching tabs before API calls are complete).
    const productKey = this.selectedProductKey;
    const retrievedProductLogs: IProductLogResponse = await store.dispatch(
      `${VuexModuleNamespaces.productLog}/${ProductLogStore.actions.retrieveProductLogs.name}`,
      {
        key: productKey,
        parentKey: store.getters[`${VuexModuleNamespaces.app}/${AppStore.getters.assetKey.name}`],
        currentPage: this.currentLogPage,
        perPage: this.logsPerPage,
        searchTerm: FilterSearchTerm[this.filterType as FilterType]
      }
    );
    if (productKey === this.selectedProductKey) {
      this.isRetrievingLogs = false;
      this.rawProductLogs = retrievedProductLogs.data ?? [];
      this.totalNumberOfProductLogs = retrievedProductLogs.total;
      this.lastRetrievedLog = retrievedProductLogs.to;
      this.currentLogPage += 1;
    }
  }

  private tasksFilter(logs: IProductLogs[]) {
    if (!this.ihdFlag) {
      return _.filter(logs, (log) => log.entityType !== this.logType.TASK);
    } else {
      return logs;
    }
  }

  private showReplyEditorArea(log: IProductLogs) {
    this.currentReplyThread = log.key;
    this.showReplyEditor = true;
    plEventBus.$emit(plEvents.showTransparentOverlay, true);
    plEventBus.$emit(plEvents.replyEditorShown);
  }

  private async lazyLoadLogs() {
    if (this.logsContainer !== null && this.logsContainer !== undefined) {
      const scrolledHeight =
        this.logsContainer.scrollTop + this.logsContainer.offsetHeight;
      const targetScrolledHeight = this.logsContainer.scrollHeight - 100;
      if (scrolledHeight > targetScrolledHeight) {
        if (!(this.isLoading || this.isLoadingMoreLogs)) {
          if (this.lastRetrievedLog !== this.totalNumberOfProductLogs - 1) {
            await this.refreshProductLogs(false);
          }
        }
      }
    }
  }

  private sortLogs(productLogs: IProductLogs[], newestFirst: boolean) {
    productLogs.sort((a: IProductLogs, b: IProductLogs) => {
      if (newestFirst) {
        // If both flagged, newest first
        // a date < b date, positive
        return (
          (Date.parse(a.lastModifiedDate) - Date.parse(b.lastModifiedDate)) * -1
        );
      } else {
        // Lowest date first
        // a date < b date, negative
        return Date.parse(a.lastModifiedDate) - Date.parse(b.lastModifiedDate);
      }
    });
  }

  private getLogsForDate(logs: IProductLogs[], date: string) {
    if (!this.ihdFlag) {
      return _.filter(logs, (log) => this.$options.filters?.formatDateLongWithoutTime(log.lastModifiedDate) === date && log.entityType !== this.logType.TASK);
    }
    return logs.filter((log) => this.$options.filters?.formatDateLongWithoutTime(log.lastModifiedDate) === date);
  }

  private resetValues(): void {
    this.rawProductLogs = [];
    this.rawProductLogKeys = [];
    this.childLogs = {};
    this.flaggedLogs = [];
    this.unflaggedLogs = [];
    this.currentLogPage = 0;
    this.lastRetrievedLog = 0;
    this.totalNumberOfProductLogs = 0;
  }

  @Watch('filterType')
  private async refreshList() {
    this.resetValues();
    await this.refreshProductLogs(true);
  }
}
