import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState} from '..';

export enum LogsMode {
  BURNER = 'BURNER',
  ASSET = 'ASSET',
  TASK = 'Task',
  TOWER = 'TOWER',
  FLAGGED = 'FLAGGED',
  ALLASSETENTRIES = 'ALLASSETENTRIES',
  ASSETLOGS= 'ASSETLOGS',
  ASSETADJUSTMENTS= 'ASSETADJUSTMENTS',
  ALLBURNERENTRIES = 'ALLBURNERENTRIES',
  BURNERLOGS= 'BURNERLOGS',
  BURNERADJUSTMENTS= 'BURNERADJUSTMENTS',
  ALLUSERENTRIES= 'ALLUSERENTRIES',
  ALLADJUSTMENTS= 'ALLADJUSTMENTS'
}

export interface IAppStoreState {
    isLoading: boolean;
    assetKey: string | null;
    burnerKey: string | null;
    logsMode: LogsMode | null;
    parentApp: string | null;
    productKey: string | null;
    taskKey: string | null;
    actionTime: number;
}

export interface AppStoreGetters extends GetterTree<IAppStoreState, IRootState> {
  isLoading(state: IAppStoreState): boolean;
  assetKey(state: IAppStoreState): string | null;
  burnerKey(state: IAppStoreState): string | null;
  logsMode(state: IAppStoreState): LogsMode | null;
  parentApp(state: IAppStoreState): string | null;
  shadowRoot(state: IAppStoreState): HTMLElement | null | undefined;
  standaloneRedirect(state: IAppStoreState): string | null;
  productKey(state: IAppStoreState): string | null;
}

export interface AppStoreMutations extends MutationTree<IAppStoreState> {
  updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void;
  updateAssetKey(state: IAppStoreState, newAssetKey: string): void;
  updateBurnerKey(state: IAppStoreState, newBurnerKey: string): void;
  updateLogsMode(state: IAppStoreState, newLogsMode: string): void;
  updateParentApp(state: IAppStoreState, newParentApp: string): void;
  updateActionTime(state: IAppStoreState): void;
}

export interface AppStoreActions extends ActionTree<IAppStoreState, IRootState> {}

export type AppContext = ActionContext<IAppStoreState, IRootState>;

export const AppStore = {
    namespaced: true as true,
    state: {
        isLoading: true,
        assetKey: null,
        burnerKey: null,
        logsMode: null,
        parentApp: null,
        productKey: null,
        FFhbBurnertree: false,
        taskKey: null,
        actionTime: new Date().getTime()
    } as IAppStoreState,
    getters:  {
      isLoading(state: IAppStoreState): boolean {
        return state.isLoading;
      },
      assetKey(state: IAppStoreState): string | null {
        return state.assetKey;
      },
      burnerKey(state: IAppStoreState): string | null {
        return state.burnerKey;
      },
      logsMode(state: IAppStoreState): LogsMode | null {
        return state.logsMode;
      },
      parentApp(state: IAppStoreState): string | null {
        return state.parentApp;
      },
      shadowRoot(state: IAppStoreState): ShadowRoot | null | undefined {
        if (state.parentApp !== null && state.parentApp !== undefined) {
          return document?.querySelector('#' + state.parentApp)?.shadowRoot?.getElementById('product-logging')?.shadowRoot;
        } else {
          return document?.querySelector('#product-logging')?.shadowRoot;
        }
      },
      standaloneRedirect(state: IAppStoreState): string | null {
        if (state.parentApp !== null && state.parentApp !== undefined && document.getElementById(state.parentApp + '-standalone')) {
            return new URL((document.getElementById(state.parentApp + '-standalone') as HTMLScriptElement)!.src).origin.toString();
        }
        return null;
      },
      productKey(state: IAppStoreState): string | null {
        if (state.logsMode === LogsMode.BURNER) {
          return state.burnerKey;
        } else if (state.logsMode === LogsMode.ASSET || state.logsMode === LogsMode.TOWER) {
          return state.assetKey;
        } else if (state.logsMode === LogsMode.TASK) {
          return state.taskKey;
        } else {
          return null;
        }
      }
    } as AppStoreGetters,
    mutations: {
      updateIsLoading(state: IAppStoreState, newIsLoading: boolean) {
        state.isLoading = newIsLoading;
      },
      updateAssetKey(state: IAppStoreState, newAssetKey: string) {
        state.assetKey = newAssetKey;
      },
      updateBurnerKey(state: IAppStoreState, newBurnerKey: string) {
        state.burnerKey = newBurnerKey;
      },
      async updateLogsMode(state: IAppStoreState, newLogsMode: string) {
        for (const item in LogsMode) {
          if (newLogsMode.toUpperCase() === item) {
            const typedString = newLogsMode.toUpperCase() as keyof typeof LogsMode;
            state.logsMode = LogsMode[typedString];
          }
        }
      },
      updateParentApp(state: IAppStoreState, newParentApp: string) {
        state.parentApp = newParentApp;
      },
      updateActionTime(state: IAppStoreState) {
        state.actionTime = new Date().getTime();
      },
    } as AppStoreMutations,
    actions: {
    } as AppStoreActions
};
