



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import MediaViewerCarouselCardItem from '@/components/media/MediaViewerCarouselCardItem.vue';
import { IProductLogMediaViewModel } from '@/view-models/productLog/product-log-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'media-viewer-carousel-card-group',
  components: {
    MediaViewerCarouselCardItem
  }
})
export default class MediaViewerCarouselCardGroup extends Vue {
  // Vue JS Props...
  @Prop({ required: true })
  public medias!: IProductLogMediaViewModel[];
  @Prop({ required: true })
  public groupIndex!: number;
  @Prop({ required: true })
  public selectedGroupIndex!: number;
  @Prop({ required: true })
  public selectedItemIndex!: number;
}
