import { IPortalAppSdk } from '../../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus() {
  return (window as any).eftEventBus as Vue;
}

export function inParentApp() {
  return parentEventBus() != null;
}

export const APP_TAG = 'product-logging' as const;

export function getParentAppSdk(): IPortalAppSdk {
  return document.getElementById(APP_TAG) as any;
}
