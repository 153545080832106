






































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store, { VuexModuleNamespaces } from '@/store/';
import { ProductLogMediaLibraryStore } from '@/store/productLogMediaLibrary/productLogMediaLibraryStore';

// Components
import MediaLibrary from '../media/MediaLibrary.vue';
import {
  plEventBus,
  plEvents,
} from '@/services/eventBus/product-logging-event-bus';
import { ProductLogStore } from '@/store/productLog/productLogStore';

Vue.use(BootstrapVue);

@Component({
  name: 'media-library-modal',
  components: {
    MediaLibrary,
  },
})
export default class MediaLibraryModal extends Vue {
  // VUE.JS Props...
  @Prop({ required: true })
  public itemKey!: string;

  // Properties...
  private disableButtons: boolean = false;

  // Lifecycle hooks...
  public created(): void {
    plEventBus.$on(
      plEvents.uploadingMedias,
      (uploading: boolean) => {
        this.disableButtons = uploading;
      }
    );
  }

  public destroyed() {
    store.commit(`${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.clearMedias.name}`);
  }

  // Event methods...
  private cancel(): void {
    this.$bvModal.hide('media-library-modal');
  }

  private async onModalShow() {
    // Recover any medias that user deleted from the library but
    // turned around and cancelled the deletion process by cancelling the modal
    ProductLogStore.state.attachedMedias.forEach(async (media) => {
      if (!ProductLogMediaLibraryStore.state.medias.some((libraryMedia) => libraryMedia.imageKey === media.imageKey)) {
         store.commit(
            `${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.appendUploadedMedia.name}`,
            media
          );
      }
    });

    if (ProductLogMediaLibraryStore.state.medias.length > 0 && ProductLogStore.state.attachedMedias.length > 0) {
      store.commit(`${VuexModuleNamespaces.productLogMediaLibrary}/${ProductLogMediaLibraryStore.mutations.setSelectedMedias.name}`,
                   ProductLogStore.state.attachedMedias);
    }
  }

  private save(): void {
    const attachedMedias = ProductLogMediaLibraryStore.state.medias.filter(
      (media) => !media.isFailedPreview && !media.isRemoved && media.isSelected
    );
    store.commit(`${VuexModuleNamespaces.productLog}/${ProductLogStore.mutations.setAttachedMedias.name}`,
                 attachedMedias);
    plEventBus.$emit(plEvents.mediaLibraryModalClosed);
    this.$bvModal.hide('media-library-modal');
  }
}
