import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { HierarchyBuilderService } from '@/services/hb/hb-service';
import { IHBBurner, IHBBurnerListServiceResponse } from '@/view-models/burner/burner-response-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState, VuexModuleNamespaces } from '..';
import { ErrorStore } from '../error/errorStore';

const initBurnerLogService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new HierarchyBuilderService(
      process.env.VUE_APP_HB_API_BASE_URL
        ? process.env.VUE_APP_HB_API_BASE_URL
        : conf.get('hbApiUrl'),
      sharedAxiosInstance
    );
  };

export interface IBurnerStoreState {
    burners: IHBBurnerListServiceResponse | null;
}

export interface BurnerStoreGetters extends GetterTree<IBurnerStoreState, IRootState> {
    getBurners: (state: IBurnerStoreState) => IHBBurnerListServiceResponse | null;
    getBurnerByKey(state: IBurnerStoreState): (key: string) => IHBBurner | undefined;
}

export interface BurnerStoreMutations extends MutationTree<IBurnerStoreState> {}

export interface BurnerStoreActions extends ActionTree<IBurnerStoreState, IRootState> {
    getBurnersForAsset(context: BurnerContext, assetKey: string): Promise<void>;
}

export type BurnerContext = ActionContext<IBurnerStoreState, IRootState>;

export const BurnerStore = {
    namespaced: true as true,
    state: {
        burners: null
    } as IBurnerStoreState,
    getters:  {
        getBurnerByKey: (state: IBurnerStoreState) => (key: string): IHBBurner | undefined => {
            return state.burners?.burners?.find((burner) => burner.burnerIdentifier === key);
        },
        getBurners: (state: IBurnerStoreState): IHBBurnerListServiceResponse | null => {
            return state.burners;
        }
    } as BurnerStoreGetters,
    mutations: {
        clearBurnersList: (state: IBurnerStoreState) => {
            state.burners = null;
        }
    } as BurnerStoreMutations,
    actions: {
        async getBurnersForAsset(context: BurnerContext, assetKey: string): Promise<void> {
            try {
                const burnerService: HierarchyBuilderService = await initBurnerLogService();
                context.state.burners = await burnerService.getBurnerList(assetKey);
            } catch (error) {
                // Catch 404s and mimick to "no burners found" response.  Else throw error
                if (error.response.status === 404) {
                    context.state.burners = {
                        assetKey: '',
                        emberHierarchyLevels: [],
                        burners: []
                    };
                } else {
                    await store.dispatch(`${VuexModuleNamespaces.error}/${ErrorStore.actions.sendErrorLog.name}`, error);
                    throw error;
                }
            }
        }
    } as BurnerStoreActions
};
